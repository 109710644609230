import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CategoryIcon from "@mui/icons-material/Category";
import GradingIcon from "@mui/icons-material/Grading";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function PaginationDesktop({
  step,
  setStep,
  stepNames,
  selectedRoom,
  propertyAvailability,
}) {
  const roomWiseIcons = {
    1: <DateRangeIcon sx={{ color: "#FFFFFF" }} />,
    2: <CategoryIcon sx={{ color: "#FFFFFF" }} />,
    3: <GradingIcon sx={{ color: "#FFFFFF" }} />,
    4: <SummarizeIcon sx={{ color: "#FFFFFF" }} />,
    5: <ManageAccountsIcon sx={{ color: "#FFFFFF" }} />,
  };
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const handleFarwardMenu = (item) => {
    if (item == 2 && propertyAvailability) {
      setStep(+item);
    }
    if ((item == 4 || item == 5) && cartItemsFromRedux?.length > 0) {
      setStep(+item);
    }
    // if (item == 3 && selectedRoom) {
    //   setStep(+item);
    // }
  };
  const handleReverseMenusetStep = (item) => {
    if (+item !== 3) {
      setStep(item);
    }

    // if (item == 3) {
    //   if (selectedRoom) {
    //     setStep(+item);
    //   }
    // } else {
    //   setStep(item);
    // }
  };
  return (
    <Box
      className="desktop-pagination"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {Object.keys(stepNames).map((item, index) => (
        <>
          <Box>
            {item <= step ? (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <CheckIcon
                  sx={{
                    color: "#1ab394",
                    // cursor: "pointer",
                    // background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
                    // WebkitBackgroundClip: "text",
                    // color: "transparent",
                    // display: "inline-block",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    cursor: "pointer",
                    background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    display: "inline-block",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleReverseMenusetStep(+item)}
                >
                  {stepNames[item]}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {roomWiseIcons[item]}
                <Typography
                  variant="subtitle1"
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => handleFarwardMenu(item)}
                >
                  {stepNames[item]}
                </Typography>
              </Box>
            )}
          </Box>
          {item < 5 && (
            <Box
              sx={{
                width: "11%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  borderBottom: "1px solid #FFFFFF",
                  // margin: "10px 0",
                }}
              ></Box>
              <KeyboardArrowRightIcon
                sx={{ color: "#FFFFFF", marginLeft: "-12px" }}
              />
            </Box>
          )}
        </>
      ))}
    </Box>
  );
}
