import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch, useSelector } from "react-redux";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import WcIcon from "@mui/icons-material/Wc";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

import axios from "axios";
import { baseUrl, baseUrlDjango } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrencySymbol } from "../common/common";
import moment from "moment";
import Footer from "../common/Footer";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import successAnimationData from "../common/celebration.json";
import HotelIcon from "@mui/icons-material/Hotel";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import { handleAmplitude } from "../amplitude/AmplitudeUtils";

export default function ConfirmationPage({}) {
  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    bgcolor: "background.paper",
    borderRadius: "4px",
    p: 2,
  };
  const navigate = useNavigate();
  const cartItemsFromRedux = useSelector((state) => state.cartItemsssssssssss);
  const dispatch = useDispatch();
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // const { bookingId } = params;
  const { bookingId } = useParams();
  // console.log("bookingId", bookingId);
  // const [totalPromotionalDiscount, setTotalPromotionalDiscount] = useState(0);
  const [itineraryData, setItineraryData] = useState(null);
  const [open, setOpen] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [hotelId, setHotelId] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [openBackdrop, setopenBackdrop] = useState(false);

  //------------Accordian-----------------
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };
  //---------------
  const handleCancelBooking = () => {
    setCancelLoader(true);
    axios
      .post(`${baseUrl}/core/api/v1/be/cancel-booking`, {
        bookingId: bookingId,
      })
      .then(() => {
        getItineraryData();
        setCancelLoader(false);
        setOpen(false);
      })
      .catch(() => {
        setCancelLoader(false);
        setOpen(false);
      });
  };
  const [totalGuest, setTotalGuest] = useState(0);
  const handleTotalGuest = (adultsArr, ChildArr) => {
    const adult = adultsArr?.reduce((cur, acc) => cur + +acc, 0);
    const child = ChildArr?.reduce((cur, acc) => cur + +acc, 0);
    setTotalGuest(adult + child);
  };
  const getItineraryData = () => {
    setopenBackdrop(true);
    axios
      .get(
        `${baseUrlDjango}/api/v2/selfservice/getItineraryData/?booking_id=${bookingId}`
      )
      .then((data) => {
        setHotelId(data.data?.hotel?.hotel_id);
        setItineraryData(data?.data);
        setCurrencySymbol(getCurrencySymbol(data?.data?.hotel?.hotel_currency));
        handleTotalGuest(
          data?.data?.booking?.adults?.split(","),
          data?.data?.booking?.children?.split(",")
        );
        setopenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (bookingId) {
      getItineraryData();
    }
  }, [bookingId]);
  useEffect(() => {
    if (bookingId && hotelId) {
      handleAmplitude(
        { bookingId: bookingId },
        "GUEST BOOKING CONFIRMED",
        hotelId
      );
    }
  }, [bookingId, hotelId]);

  return (
    <Box className="booking-page">
      <Box className="summary-wrapper">
        <Box className="summary-item">
          <Box>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backdropFilter: openBackdrop ? "blur(4px)" : "none",
              }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
          <Lottie options={successAnimation} height={220} width={220} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" align="center">
              Hey{" "}
              <span style={{ color: "#1AAC9B" }}>
                {itineraryData?.booking?.user_name}
              </span>
              , your booking is{" "}
              {itineraryData?.booking?.booking_status?.toLowerCase()}!
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Hotel details</Typography>
          </Box>

          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              p: 2,
              gap: 1,
              borderRadius: "8px",
              border: "1px solid #FFFFFF",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <HotelIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  {itineraryData?.hotel?.hotel_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <LocationOnIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  {itineraryData?.hotel?.hotel_address}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <CallIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  {itineraryData?.hotel?.hotel_contact}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <AlternateEmailIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  {itineraryData?.hotel?.hotel_email}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Booking details</Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              p: 2,
              gap: 1,
              borderRadius: "8px",
              border: "1px solid #FFFFFF",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <ConfirmationNumberIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">{bookingId}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <CalendarMonthIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  {moment(itineraryData?.booking?.booking_checkin).format(
                    "Do MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(itineraryData?.booking?.booking_checkout).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <NightShelterIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  Room stays: {itineraryData?.booking?.room_count}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <WcIcon sx={{ color: "#FFFFFF" }} />
                <Typography variant="subtitle2">
                  Total guest: {totalGuest}
                </Typography>
              </Box>
            </Box>

            {/* <Box>
              <Button
                variant="custom-button"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  borderWidth: "2px",
                }}
                onClick={() => setOpen(true)}
                disabled={
                  itineraryData?.booking?.booking_status !== "CONFIRMED"
                }
              >
                Cancel booking
              </Button>
            </Box> */}
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Breakdown</Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              // p: 2,
              pt: 2,
              pb: 2,
              borderRadius: "8px",
              border: "1px solid #FFFFFF",
              display: "flex",
              flexDirection: "column",
              mb: 2,
            }}
          >
            {itineraryData?.booking?.room_type_name_counts
              ?.split(", ")
              ?.map((items) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pl: 2,
                    pr: 2,
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle2">
                    {items.substr(items?.split(" ")[0].length + 1)} X{" "}
                    {items?.split(" ")?.[0]}
                  </Typography>
                  <Typography variant="subtitle2">
                    {currencySymbol}
                    {(+itineraryData?.booking?.reservation_room_info_list?.filter(
                      (elm) =>
                        elm?.roomTypeName ===
                        items.substr(items?.split(" ")[0].length + 1)
                    )?.[0]?.roomRate)?.toFixed(2)}
                  </Typography>
                </Box>
              ))}

            <Accordion
              expanded={expanded}
              onChange={handleChange}
              sx={{
                borderTop: "0.5px solid #FFFFFF",
                borderBottom: "0.5px solid #FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#FFFFFF", mr: "-3px" }} />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ background: "black" }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Fee, taxes and discount
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{ background: "black" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Room Price</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    {currencySymbol}{" "}
                    {itineraryData?.booking?.total_room_pretax_amount?.toFixed(
                      2
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Tax</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    {currencySymbol}{" "}
                    {itineraryData?.booking?.total_tax_amount?.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Fee</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    {currencySymbol}{" "}
                    {itineraryData?.booking?.total_fee_amount?.toFixed(2)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Total Price</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    {currencySymbol}{" "}
                    {itineraryData?.booking?.total_amount_with_services?.toFixed(
                      2
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Amount Paid</Typography>
                  <Typography variant="subtitle2">
                    {currencySymbol}
                    {itineraryData?.booking?.total_online_payment?.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",

                    // pr: 1,
                  }}
                >
                  <Typography variant="subtitle2">Balance due</Typography>
                  <Typography variant="subtitle2">
                    {" "}
                    {currencySymbol}{" "}
                    {itineraryData?.booking?.balance_due?.toFixed(2)}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pl: 2,
                pr: 2,
                mt: 1,
              }}
            >
              <Typography variant="subtitle2">Total</Typography>
              <Typography variant="subtitle2">
                {currencySymbol}{" "}
                {itineraryData?.booking?.total_amount_with_services?.toFixed(2)}
              </Typography>
            </Box>
          </Box>

          {/* {cartItemsFromRedux?.map((items) => (
            <Box className="summary-item-card">
              <Card
                className="summary-card-header"
                sx={{ position: "relative" }}
              >
                <Box className="summary-media-container">
                  {allRoomTypeMap?.[items?.selectedRoom]?.roomTypeImages
                    ?.length > 1 ? (
                    <PropertyImageSlider
                      images={
                        allRoomTypeMap?.[items?.selectedRoom]?.roomTypeImages
                      }
                    />
                  ) : (
                    <CardMedia
                      className="cardmedia"
                      component="img"
                      image={
                        allRoomTypeMap?.[items?.selectedRoom]
                          ?.roomTypeImages?.[0] || defaultHotelImage
                      }
                      alt={`defaultHotelImage`}
                    />
                  )}
                </Box>

                <Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Box>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <HomeIcon sx={{ color: "#FFFFFF" }} />
                        <Typography variant="h6">
                          {allRoomTypeMap?.[items?.selectedRoom]?.roomTypeName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <PersonIcon sx={{ color: "#FFFFFF" }} />
                        <Box className="summary-adult-child">
                          <Typography variant="subtitle2">
                            Adults : {items?.totalAdult}{" "}
                          </Typography>
                          <Typography variant="subtitle2">
                            Children : {items?.totalChild}{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <EqualizerIcon sx={{ color: "#FFFFFF" }} />
                        <Typography variant="subtitle2">
                          {
                            allRoomTypeMap?.[
                              items?.selectedRoom
                            ]?.combos?.[0]?.rates?.filter(
                              (elm) =>
                                elm?.ratePlanId === items?.selectedRatePlan
                            )?.[0]?.ratePlanName
                          }
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <PolicyIcon sx={{ color: "#FFFFFF" }} />
                        <Typography variant="subtitle2">
                          {
                            allRoomTypeMap?.[
                              items?.selectedRoom
                            ]?.combos?.[0]?.rates?.filter(
                              (elm) =>
                                elm?.ratePlanId === items?.selectedRatePlan
                            )?.[0]?.cancellationPolicyInfo?.policyRule
                          }
                        </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                        {currencySymbol}
                        {(
                          items?.selectedRoomCharges * currencyConversionFactor
                        )?.toFixed(2)}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Box>
          ))} */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
            <Button
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
              onClick={() => navigate(`/${hotelId}`)}
            >
              Book another room
            </Button>
          </Box>
          <Footer />
        </Box>
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="center">Do you want to proceed</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              color="success"
              onClick={handleCancelBooking}
            >
              {cancelLoader ? <CircularProgress size="1.5rem" /> : "Yes"}
            </Button>{" "}
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
