import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../common/Header";
import { Box } from "@mui/material";
import NotFound from "../common/NotFound";
import PGFailed from "../common/PGFailed";
import ConfirmationPage from "../pages/ConfirmationPage";

export default function Routers() {
  return (
    <Box className="booking-engine">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Login />}></Route> */}

          <Route path="/:hotelId" element={<Header />}>
            {/* path: "/:hotelId/:hotelName/:hotelCity" */}
            <Route index element={<Home />} />
            <Route path="/:hotelId/:hotelName" element={<Home />} />
            <Route path="/:hotelId/:hotelName/:hotelCity" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/booking-confirmation/:bookingId"
            element={<ConfirmationPage />}
          />
          <Route path="/access-denied" element={<NotFound />} />
          <Route
            path="/booking-failure/:hotelId/:refId"
            element={<PGFailed />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
