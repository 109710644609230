import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import React from "react";
import defaultHotelImage from "../asset/default_img.jpg";
import DateSelectionHeader from "./DateSelectionHeader";
import PropertyCategorySliding from "./PropertyCategorySliding";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "../common/Footer";
import { useSelector } from "react-redux";
export default function ProperyCategory({
  setStep,
  allRoomTypeMap,
  setSelectedRoom,
  setSelectedRatePlan,
  currencySymbol,
  adults,
  children,
  dateRange,
  bookingDaysCount,
  currencyConversionFactor,
  setCartItemUid,
  setEditableRateItem,
  setIsEditCardItem,
  roomTypeImages,
}) {
  console.log("roomTypeImages", roomTypeImages);
  const handleShowRates = (roomTypeId) => {
    setSelectedRoom(roomTypeId);
    setStep(3);
  };
  const [totalAvailableAdults, setTotalAvailableAdults] = useState(0);
  const [totalAvailableChilld, setTotalAvailableChild] = useState(0);

  useEffect(() => {
    // console.log("allRoomTypeMap", allRoomTypeMap);
    let countAdult = 0;
    let countChild = 0;
    if (allRoomTypeMap) {
      Object.keys(allRoomTypeMap)?.map((item) => {
        countAdult +=
          allRoomTypeMap?.[item]?.maxOccupancy *
          allRoomTypeMap?.[item]?.combos?.[0]?.availableRooms;
        countChild +=
          (allRoomTypeMap?.[item]?.maxPossible -
            allRoomTypeMap?.[item]?.maxOccupancy) *
          allRoomTypeMap?.[item]?.combos?.[0]?.availableRooms;
      });

      setTotalAvailableAdults(countAdult);
      setTotalAvailableChild(countChild);
      console.log("count", countAdult, countChild);
    }
  }, [allRoomTypeMap]);
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  useEffect(() => {
    setSelectedRatePlan("");
    setSelectedRoom("");
    setCartItemUid("");
    setEditableRateItem("");
    setIsEditCardItem(false);
  }, []);
  return (
    <Box className="category-wrapper">
      <Box className="category-item">
        <Box className="category-header">
          <DateSelectionHeader
            adults={adults}
            children={children}
            dateRange={dateRange}
            setStep={setStep}
            bookingDaysCount={bookingDaysCount}
          />
        </Box>
        {(adults > totalAvailableAdults || children > totalAvailableChilld) && (
          <Box className="category-header" sx={{ mt: 3 }}>
            <Alert severity="warning">
              Property is currently sold out. Please consider searching for
              alternative dates
            </Alert>
          </Box>
        )}

        {Object.keys(allRoomTypeMap).map((roomTypeId) => (
          <Box className="category-card">
            <Card
              sx={{
                width: "100%",
                mt: 2,
                border: "1px solid #FFFFFF",
                background: "black",
                borderRadius: "8px",
              }}
            >
              <CardActionArea>
                {roomTypeImages?.[roomTypeId]?.length > 1 ? (
                  <PropertyCategorySliding
                    images={roomTypeImages?.[roomTypeId]}
                  />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "calc(100% * 2 / 3)",
                    }}
                  >
                    <CardMedia
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      component="img"
                      image={
                        roomTypeImages?.[roomTypeId]?.[0]?.imageUrl ||
                        defaultHotelImage
                      }
                      alt="defaultHotelImage"
                    />
                  </Box>
                )}

                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography variant="h6">
                        {allRoomTypeMap[roomTypeId]?.roomTypeName}
                      </Typography>
                      {/* <Typography variant="body2">Adults : 1</Typography> */}
                      <Typography variant="body2">
                        Availability:{" "}
                        {
                          allRoomTypeMap[roomTypeId]?.combos?.[0]
                            ?.availableRooms
                        }
                      </Typography>
                    </Box>
                    <Box>
                      {allRoomTypeMap[roomTypeId]?.combos?.[0]?.rates?.reduce(
                        (prev, current) => {
                          return prev?.priceMap?.[1] -
                            (prev?.discountMap?.[1] || 0) <
                            current?.priceMap?.[1] -
                              (current?.discountMap?.[1] || 0)
                            ? prev
                            : current;
                        }
                      )?.discountMap?.[1] && (
                        <Chip
                          label={
                            <Typography variant="subtitle1">
                              {(
                                (allRoomTypeMap[
                                  roomTypeId
                                ]?.combos?.[0]?.rates?.reduce(
                                  (prev, current) => {
                                    return prev?.priceMap?.[1] -
                                      (prev?.discountMap?.[1] || 0) <
                                      current?.priceMap?.[1] -
                                        (current?.discountMap?.[1] || 0)
                                      ? prev
                                      : current;
                                  }
                                )?.discountMap?.[1] *
                                  100) /
                                allRoomTypeMap[
                                  roomTypeId
                                ]?.combos?.[0]?.rates?.reduce(
                                  (prev, current) => {
                                    return prev?.priceMap?.[1] -
                                      (prev?.discountMap?.[1] || 0) <
                                      current?.priceMap?.[1] -
                                        (current?.discountMap?.[1] || 0)
                                      ? prev
                                      : current;
                                  }
                                )?.priceMap?.[1]
                              ).toFixed(1)}
                              % off
                            </Typography>
                          }
                          variant="outlined"
                        />
                      )}
                      {allRoomTypeMap[roomTypeId]?.combos?.[0]?.rates?.reduce(
                        (prev, current) => {
                          return prev?.priceMap?.[1] -
                            (prev?.discountMap?.[1] || 0) <
                            current?.priceMap?.[1] -
                              (current?.discountMap?.[1] || 0)
                            ? prev
                            : current;
                        }
                      )?.discountMap?.[1] && (
                        <Typography
                          variant="h6"
                          sx={{ textDecoration: "line-through" }}
                        >
                          {currencySymbol}
                          {(
                            allRoomTypeMap[
                              roomTypeId
                            ]?.combos?.[0]?.rates?.reduce((prev, current) => {
                              return prev?.priceMap?.[1] -
                                (prev?.discountMap?.[1] || 0) <
                                current?.priceMap?.[1] -
                                  (current?.discountMap?.[1] || 0)
                                ? prev
                                : current;
                            })?.priceMap?.[1] * currencyConversionFactor
                          )?.toFixed(2)}
                        </Typography>
                      )}
                      <Typography variant="h6">
                        {currencySymbol}
                        {/* {(
                          allRoomTypeMap[roomTypeId]?.combos?.[0]?.rates?.[0]
                            ?.actualPrice * currencyConversionFactor
                        )?.toFixed(2)} */}

                        {(
                          (allRoomTypeMap[
                            roomTypeId
                          ]?.combos?.[0]?.rates?.reduce((prev, current) => {
                            return prev?.priceMap?.[1] -
                              (prev?.discountMap?.[1] || 0) <
                              current?.priceMap?.[1] -
                                (current?.discountMap?.[1] || 0)
                              ? prev
                              : current;
                          })?.priceMap?.[1] -
                            (allRoomTypeMap[
                              roomTypeId
                            ]?.combos?.[0]?.rates?.reduce((prev, current) => {
                              return prev?.priceMap?.[1] -
                                (prev?.discountMap?.[1] || 0) <
                                current?.priceMap?.[1] -
                                  (current?.discountMap?.[1] || 0)
                                ? prev
                                : current;
                            })?.discountMap?.[1] || 0)) *
                          currencyConversionFactor
                        )?.toFixed(2)}
                      </Typography>
                      <Typography variant="caption">
                        *Excluding taxes & fees
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  variant="custom-button"
                  sx={{ width: "100%", borderRadius: "27px", mb: 2 }}
                  onClick={() => handleShowRates(roomTypeId)}
                  disabled={
                    allRoomTypeMap[roomTypeId]?.combos?.[0]?.availableRooms <
                      1 ||
                    cartItemsFromRedux
                      ?.filter((item) => item?.selectedRoom === roomTypeId)
                      ?.reduce((acc, cur) => acc + cur?.propertyRooms, 0) >=
                      allRoomTypeMap[roomTypeId]?.combos?.[0]?.availableRooms
                  }
                >
                  {allRoomTypeMap[roomTypeId]?.combos?.[0]?.availableRooms < 1
                    ? "Sold out"
                    : cartItemsFromRedux
                        ?.filter((item) => item?.selectedRoom === roomTypeId)
                        ?.reduce((acc, cur) => acc + cur?.propertyRooms, 0) >=
                      allRoomTypeMap[roomTypeId]?.combos?.[0]?.availableRooms
                    ? "No more rooms"
                    : "Show rates"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))}

        <Footer />
      </Box>
    </Box>
  );
}
