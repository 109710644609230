import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import defaultHotelImage from "../asset/default_img.jpg";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { saveCartItem } from "../redux/slice/slice";
import DateSelectionHeader from "./DateSelectionHeader";
import PropertyImageSlider from "./PropertyImageSlider";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import Footer from "../common/Footer";
import { v4 as uuidv4 } from "uuid";
import OtaRatesComparison from "./OtaRatesComparison";
export default function PropertyRates({
  setStep,
  allRoomTypeMap,
  currencySymbol,
  selectedRoom,
  selectedRatePlan,
  setSelectedRatePlan,
  isEditCardItem = false,
  adults,
  children,
  dateRange,
  bookingDaysCount,
  editableRateItem,
  currencyConversionFactor,
  cartItemUid,
  adultCountFromParams,
  rateCompareData,
  roomTypeImages,
}) {
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const propertyInfo = useSelector((state) => state.PropertyInfo);
  const dispatch = useDispatch();
  const [moreRatePlans, setMoreRatePlans] = useState(false);

  const [roomsCountInCart, setRoomsCountInCart] = useState(0);
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [roomWiseAdults, setRoomWiseAdults] = useState({ Room1: 1 });
  const [roomWiseChildren, setRoomWiseChildren] = useState({ Room1: 0 });

  const [selectedRoomCharges, setSelectedRoomCharges] = useState(0);
  const [selectedRoomTaxes, setSelectedRoomTaxes] = useState(0);
  const [
    selectedRoomChargesAfterDiscount,
    setSelectedRoomChargesAfterDiscount,
  ] = useState(0);
  const [selectedRoomTaxesAfterDiscount, setSelectedRoomTaxesAfterDiscount] =
    useState(0);
  const [promotionThere, setPromotionThere] = useState(false);
  const [promotionalDiscount, setPromotionalDiscount] = useState(0);
  // const [moreEnhance, setMoreEnhance] = useState(false);
  // const [selectedAddons, setSelectedAddons] = useState([]);
  // const [addonsCharges, setAddonsCharges] = useState(0);
  // const [addonsTax, setAddonstax] = useState(0);

  const handleRooms = (mode) => {
    // debugger;
    if (selectedRatePlan) {
      const arr = cartItemsFromRedux?.filter(
        (elm) =>
          // elm?.selectedRoom === selectedRoom &&
          // elm?.selectedRatePlan === selectedRatePlan &&
          elm?.uid === cartItemUid
      );
      if (
        mode === "inc" &&
        (arr?.length > 0
          ? allRoomTypeMap?.[selectedRoom]?.combos[0]?.availableRooms >
            roomsCountInCart - arr[0]?.propertyRooms + propertyRooms
          : allRoomTypeMap?.[selectedRoom]?.combos[0]?.availableRooms >
            roomsCountInCart + propertyRooms)
      ) {
        const adultObj = { ...roomWiseAdults };
        const childObj = { ...roomWiseChildren };
        adultObj[`Room${propertyRooms + 1}`] =
          allRoomTypeMap[selectedRoom]?.defaultOccupancy;
        childObj[`Room${propertyRooms + 1}`] = 0;
        setRoomWiseAdults(adultObj);
        setRoomWiseChildren(childObj);

        setPropertyRooms((prev) => +prev + 1);
      }
      if (mode === "dec" && propertyRooms > 1) {
        const adultObj = { ...roomWiseAdults };
        const childObj = { ...roomWiseChildren };
        adultObj[`Room${propertyRooms}`] = null;
        childObj[`Room${propertyRooms}`] = null;
        setRoomWiseAdults(adultObj);
        setRoomWiseChildren(childObj);
        setPropertyRooms((prev) => +prev - 1);
      }
    }
  };
  const handleAdult = (mode, roomNumber) => {
    if (
      mode === "inc" &&
      roomWiseAdults[`Room${roomNumber}`] <
        allRoomTypeMap[selectedRoom]?.maxOccupancy &&
      roomWiseAdults[`Room${roomNumber}`] +
        roomWiseChildren[`Room${roomNumber}`] <
        allRoomTypeMap[selectedRoom]?.maxPossible
    ) {
      const adultObj = { ...roomWiseAdults };
      adultObj[`Room${roomNumber}`] = roomWiseAdults[`Room${roomNumber}`] + 1;
      setRoomWiseAdults(adultObj);
    }
    if (mode === "dec" && roomWiseAdults[`Room${roomNumber}`] > 1) {
      const adultObj = { ...roomWiseAdults };
      adultObj[`Room${roomNumber}`] = roomWiseAdults[`Room${roomNumber}`] - 1;
      setRoomWiseAdults(adultObj);
    }
  };

  const handleChildren = (mode, roomNumber) => {
    if (
      mode === "inc" &&
      roomWiseChildren[`Room${roomNumber}`] <
        allRoomTypeMap[selectedRoom]?.maxChildren &&
      roomWiseAdults[`Room${roomNumber}`] +
        roomWiseChildren[`Room${roomNumber}`] <
        allRoomTypeMap[selectedRoom]?.maxPossible
    ) {
      const childObj = { ...roomWiseChildren };
      childObj[`Room${roomNumber}`] = roomWiseChildren[`Room${roomNumber}`] + 1;
      setRoomWiseChildren(childObj);
    }
    if (mode === "dec" && roomWiseChildren[`Room${roomNumber}`] > 0) {
      const childObj = { ...roomWiseChildren };
      childObj[`Room${roomNumber}`] = roomWiseChildren[`Room${roomNumber}`] - 1;
      setRoomWiseChildren(childObj);
    }
  };

  const handleRoomCharges = () => {
    // debugger;
    if (selectedRatePlan) {
      const priceMap = allRoomTypeMap?.[
        selectedRoom
      ]?.combos?.[0]?.rates?.filter(
        (rate) => rate?.ratePlanId === selectedRatePlan
      )?.[0]?.priceMap;

      const taxMap = allRoomTypeMap?.[selectedRoom]?.combos?.[0]?.rates?.filter(
        (rate) => rate?.ratePlanId === selectedRatePlan
      )?.[0]?.taxes;

      const discountMap = allRoomTypeMap?.[
        selectedRoom
      ]?.combos?.[0]?.rates?.filter(
        (rate) => rate?.ratePlanId === selectedRatePlan
      )?.[0]?.discountMap;
      const taxAfterDiscountMap = allRoomTypeMap?.[
        selectedRoom
      ]?.combos?.[0]?.rates?.filter(
        (rate) => rate?.ratePlanId === selectedRatePlan
      )?.[0]?.taxAfterDiscountMap;

      let amount = 0;
      let tax = 0;
      let discountedAmount = 0;
      let discountedTax = 0;
      let promotionalDiscounts = 0;
      Array.from({ length: propertyRooms }, (_, index) => index + 1)?.map(
        (items) => {
          let keyofPriceMap;
          if (roomWiseChildren?.[`Room${items}`]) {
            keyofPriceMap =
              roomWiseAdults?.[`Room${items}`] +
              "+" +
              roomWiseChildren?.[`Room${items}`];
          } else {
            keyofPriceMap = roomWiseAdults?.[`Room${items}`];
          }
          amount += priceMap?.[keyofPriceMap];
          tax += taxMap?.[keyofPriceMap];

          if (
            discountMap &&
            Object?.keys(discountMap)?.length > 0 &&
            taxAfterDiscountMap &&
            Object?.keys(taxAfterDiscountMap)?.length > 0
          ) {
            discountedAmount +=
              priceMap?.[keyofPriceMap] - discountMap?.[keyofPriceMap];
            discountedTax += taxAfterDiscountMap?.[keyofPriceMap];
            promotionalDiscounts += discountMap?.[keyofPriceMap];
          } else {
            discountedAmount += priceMap?.[keyofPriceMap];
            discountedTax += taxMap?.[keyofPriceMap];
          }
        }
      );
      setSelectedRoomCharges(amount);
      setSelectedRoomTaxes(tax);
      setSelectedRoomChargesAfterDiscount(discountedAmount);
      setSelectedRoomTaxesAfterDiscount(discountedTax);
      setPromotionThere(
        discountMap && Object?.keys(discountMap)?.length > 0 ? true : false
      );
      setPromotionalDiscount(promotionalDiscounts);
    }
  };

  const handleToAddCartItem = () => {
    // debugger;
    const existingCartItems = [...cartItemsFromRedux];
    if (isEditCardItem) {
      const index = existingCartItems?.findIndex(
        (elm) =>
          // elm?.selectedRoom === selectedRoom &&
          // elm?.selectedRatePlan === editableRateItem &&
          elm?.uid === cartItemUid
      );

      if (index >= 0) {
        existingCartItems.splice(index, 1);
      }
    }

    const newCartItem = {};
    newCartItem.uid = isEditCardItem ? cartItemUid : uuidv4();
    newCartItem.selectedRoom = selectedRoom;
    newCartItem.selectedRatePlan = selectedRatePlan;
    newCartItem.propertyRooms = propertyRooms;
    newCartItem.roomWiseAdults = roomWiseAdults;
    newCartItem.roomWiseChildren = roomWiseChildren;
    newCartItem.selectedRoomCharges = selectedRoomCharges;
    newCartItem.selectedRoomTaxes = selectedRoomTaxes;
    newCartItem.selectedRoomChargesAfterDiscount =
      selectedRoomChargesAfterDiscount;
    newCartItem.selectedRoomTaxesAfterDiscount = selectedRoomTaxesAfterDiscount;
    newCartItem.promotionThere = promotionThere;
    newCartItem.promotionalDiscount = promotionalDiscount;
    const totalAdult = Object.values(roomWiseAdults).reduce(
      (cur, acc) => cur + acc,
      0
    );
    const totalChild = Object.values(roomWiseChildren).reduce(
      (cur, acc) => cur + acc,
      0
    );
    newCartItem.totalAdult = totalAdult;
    newCartItem.totalChild = totalChild;

    const index = existingCartItems.findIndex(
      (obj) =>
        // obj.selectedRoom === newCartItem.selectedRoom &&
        // obj.selectedRatePlan === newCartItem.selectedRatePlan &&
        obj.uid === newCartItem.uid
    );
    if (propertyRooms > 0) {
      if (index !== -1 && isEditCardItem) {
        existingCartItems[index] = newCartItem;
      } else {
        existingCartItems.push(newCartItem);
      }
    }

    dispatch(saveCartItem(existingCartItems));
    setStep(4);
  };

  // useEffect(() => {
  //   handleAddonCharges();
  // }, [selectedAddons]);

  // if (!bookedRooms) {
  //   setPropertyRooms(1);
  //   setRoomWiseAdults({
  //     Room1: allRoomTypeMap?.[selectedRoom]?.defaultOccupancy,
  //   });
  //   setRoomWiseChildren({ Room1: 0 });
  // }
  const handelingCartOnRateSelection = () => {
    const cartOfSelectedRoom = cartItemsFromRedux?.filter(
      (elm) => elm?.selectedRoom === selectedRoom
    );
    const indexOfSelectedRoomAndRatePlan = cartOfSelectedRoom?.findIndex(
      (elm) =>
        elm?.selectedRatePlan === selectedRatePlan && elm?.uid === cartItemUid
    );

    if (indexOfSelectedRoomAndRatePlan >= 0 && isEditCardItem) {
      setPropertyRooms(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]?.propertyRooms
      );
      setRoomWiseAdults(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]?.roomWiseAdults
      );
      setRoomWiseChildren(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]?.roomWiseChildren
      );
      setSelectedRoomCharges(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]
          ?.selectedRoomCharges
      );
      setSelectedRoomTaxes(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]?.selectedRoomTaxes
      );
      setSelectedRoomChargesAfterDiscount(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]
          ?.selectedRoomChargesAfterDiscount
      );
      setSelectedRoomTaxesAfterDiscount(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]
          ?.selectedRoomTaxesAfterDiscount
      );
      setPromotionThere(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]?.promotionThere
      );
      setPromotionalDiscount(
        cartItemsFromRedux?.[indexOfSelectedRoomAndRatePlan]
          ?.promotionalDiscount
      );
    }
    // else {
    //   if (
    //     roomsCountInCart <
    //     allRoomTypeMap?.[selectedRoom]?.combos[0]?.availableRooms
    //   ) {
    //     applyDefaultRoomStructure();
    //   } else {
    //     setPropertyRooms(0);
    //     setRoomWiseAdults({});
    //     setRoomWiseChildren({});
    //   }
    // }
  };

  const handelingReduxCarts = () => {
    const cartOfSelectedRoom = cartItemsFromRedux?.filter(
      (elm) => elm?.selectedRoom === selectedRoom
    );

    let bookedRooms = cartOfSelectedRoom?.reduce(
      (cur, acc) => cur + acc?.propertyRooms,
      0
    );
    setRoomsCountInCart(bookedRooms);
  };
  const applyDefaultRoomStructure = () => {
    setPropertyRooms(1);
    if (adultCountFromParams > 0) {
      setRoomWiseAdults({
        Room1: +adultCountFromParams,
      });
    } else {
      setRoomWiseAdults({
        Room1: allRoomTypeMap?.[selectedRoom]?.defaultOccupancy,
      });
    }

    setRoomWiseChildren({ Room1: 0 });
  };

  useEffect(() => {
    handleRoomCharges();
  }, [selectedRatePlan, roomWiseAdults, roomWiseChildren, propertyRooms]);

  useEffect(() => {
    selectedRatePlan ||
      setSelectedRatePlan(
        allRoomTypeMap?.[selectedRoom]?.combos?.[0]?.rates?.[0]?.ratePlanId
      );
  }, [allRoomTypeMap]);

  useEffect(() => {
    applyDefaultRoomStructure();
  }, [allRoomTypeMap, adultCountFromParams]);

  useEffect(() => {
    handelingReduxCarts();
  }, [cartItemsFromRedux]);
  useEffect(() => {
    handelingCartOnRateSelection();
  }, [selectedRatePlan]);

  // const handleAddOns = (id) => {
  //   const arr = [...selectedAddons];
  //   if (arr.includes(id)) {
  //     arr.splice(arr.indexOf(id), 1);
  //   } else {
  //     if (propertyRooms) {
  //       arr.push(id);
  //     }
  //   }
  //   setSelectedAddons(arr);
  // };

  // const handleAddonCharges = () => {
  //   const filteredArray = addonsList?.filter((item) =>
  //     selectedAddons.includes(item?.id)
  //   );
  //   const sum = filteredArray?.reduce((acc, curr) => {
  //     return acc + curr.product_price;
  //   }, 0);
  //   setAddonsCharges(sum);
  // };
  const [renderRatePlans, setRenderRatePlans] = useState([]);
  useEffect(() => {
    const ratePlanArray = [];
    if (allRoomTypeMap && selectedRoom && propertyRooms) {
      allRoomTypeMap?.[selectedRoom]?.combos[0]?.rates?.map((item) => {
        console.log("itemitem", item);
        const singleRatePlan = {};
        singleRatePlan.ratePlanId = item?.ratePlanId;
        singleRatePlan.ratePlanName = item?.ratePlanName;
        singleRatePlan.ratePlanDescription = item?.ratePlanDescription;
        singleRatePlan.cancellationPolicyInfo =
          item?.cancellationPolicyInfo?.policyRule;
        let selectedRatePlan = item?.ratePlanId;

        const priceMap = allRoomTypeMap?.[
          selectedRoom
        ]?.combos?.[0]?.rates?.filter(
          (rate) => rate?.ratePlanId === selectedRatePlan
        )?.[0]?.priceMap;

        const taxMap = allRoomTypeMap?.[
          selectedRoom
        ]?.combos?.[0]?.rates?.filter(
          (rate) => rate?.ratePlanId === selectedRatePlan
        )?.[0]?.taxes;

        const discountMap = allRoomTypeMap?.[
          selectedRoom
        ]?.combos?.[0]?.rates?.filter(
          (rate) => rate?.ratePlanId === selectedRatePlan
        )?.[0]?.discountMap;
        const taxAfterDiscountMap = allRoomTypeMap?.[
          selectedRoom
        ]?.combos?.[0]?.rates?.filter(
          (rate) => rate?.ratePlanId === selectedRatePlan
        )?.[0]?.taxAfterDiscountMap;

        let amount = 0;
        let tax = 0;
        let discountedAmount = 0;
        let discountedTax = 0;
        let promotionalDiscounts = 0;
        Array.from({ length: propertyRooms }, (_, index) => index + 1)?.map(
          (items) => {
            let keyofPriceMap;
            if (roomWiseChildren?.[`Room${items}`]) {
              keyofPriceMap =
                roomWiseAdults?.[`Room${items}`] +
                "+" +
                roomWiseChildren?.[`Room${items}`];
            } else {
              keyofPriceMap = roomWiseAdults?.[`Room${items}`];
            }
            amount += priceMap?.[keyofPriceMap];
            tax += taxMap?.[keyofPriceMap];

            if (
              discountMap &&
              Object?.keys(discountMap)?.length > 0 &&
              taxAfterDiscountMap &&
              Object?.keys(taxAfterDiscountMap)?.length > 0
            ) {
              discountedAmount +=
                priceMap?.[keyofPriceMap] - discountMap?.[keyofPriceMap];
              discountedTax += taxAfterDiscountMap?.[keyofPriceMap];
              promotionalDiscounts += discountMap?.[keyofPriceMap];
            } else {
              discountedAmount += priceMap?.[keyofPriceMap];
              discountedTax += taxMap?.[keyofPriceMap];
            }
          }
        );
        singleRatePlan.SelectedRoomCharges = amount;
        singleRatePlan.SelectedRoomTaxes = tax;
        singleRatePlan.SelectedRoomChargesAfterDiscount = discountedAmount;
        singleRatePlan.SelectedRoomTaxesAfterDiscount = discountedTax;
        singleRatePlan.PromotionThere =
          discountMap && Object?.keys(discountMap)?.length > 0 ? true : false;
        singleRatePlan.PromotionalDiscount = promotionalDiscounts;
        ratePlanArray.push(singleRatePlan);
      });
    }
    setRenderRatePlans(ratePlanArray);
    console.log("ratePlanArrayratePlanArray", ratePlanArray);
  }, [
    allRoomTypeMap,
    selectedRoom,
    roomWiseAdults,
    roomWiseChildren,
    propertyRooms,
  ]);
  const [expandDescription, setExpandDescription] = useState(false);
  const toggleExpand = () => {
    setExpandDescription((prev) => !prev);
  };
  return (
    <Box className="rate-wrapper">
      <Box className="rate-item">
        <Box
          sx={{
            width: "100%",
            border: "1px solid #FFFFFF",
            borderRadius: 2,
            background: "black",
            mb: 2,
          }}
        >
          <DateSelectionHeader
            adults={adults}
            children={children}
            dateRange={dateRange}
            setStep={setStep}
            bookingDaysCount={bookingDaysCount}
          />
        </Box>
        {rateCompareData && (
          <Box
            sx={{
              width: "100%",
              border: "1px solid #FFFFFF",
              borderRadius: 2,
              background: "black",
              mb: 2,
            }}
          >
            <OtaRatesComparison
              rateCompareData={rateCompareData}
              selectedRoom={selectedRoom}
              currencyConversionFactor={currencyConversionFactor}
              currencySymbol={currencySymbol}
            />
          </Box>
        )}
        <Box className="rate-item-card">
          <Card className="rate-card-header">
            <Box className="rate-media-container">
              {roomTypeImages?.[selectedRoom]?.length > 1 ? (
                <PropertyImageSlider images={roomTypeImages?.[selectedRoom]} />
              ) : (
                <CardMedia
                  className="cardmedia"
                  component="img"
                  image={
                    roomTypeImages?.[selectedRoom]?.[0]?.imageUrl ||
                    defaultHotelImage
                  }
                  alt={`defaultHotelImage`}
                />
              )}
            </Box>

            <Box>
              <CardContent sx={{ paddingBottom: "8px !important" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <HomeIcon sx={{ color: "#FFFFFF" }} />
                    <Typography variant="h6">
                      {allRoomTypeMap?.[selectedRoom]?.roomTypeName}
                    </Typography>
                  </Box>
                  {/* <Box className="rate-availability"> */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <ManageAccountsIcon sx={{ color: "#FFFFFF" }} />
                    <Typography variant="subtitle1">
                      Availability :{" "}
                      {
                        allRoomTypeMap?.[selectedRoom]?.combos[0]
                          ?.availableRooms
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <SupervisorAccountIcon sx={{ color: "#FFFFFF" }} />
                    <Typography variant="subtitle1">
                      Maximum persons :{" "}
                      {allRoomTypeMap?.[selectedRoom]?.maxOccupancy}
                    </Typography>
                  </Box>
                  {/* </Box> */}
                  {propertyInfo?.roomTypeList?.filter(
                    (rooms) => rooms?.roomTypeId === selectedRoom
                  )[0]?.roomTypeDescription &&
                    propertyInfo?.roomTypeList?.filter(
                      (rooms) => rooms?.roomTypeId === selectedRoom
                    )[0]?.roomTypeDescription !== "" &&
                    propertyInfo?.roomTypeList?.filter(
                      (rooms) => rooms?.roomTypeId === selectedRoom
                    )[0]?.roomTypeDescription !== " " && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <DescriptionIcon sx={{ color: "#FFFFFF" }} />
                        {propertyInfo?.roomTypeList?.filter(
                          (rooms) => rooms?.roomTypeId === selectedRoom
                        )[0]?.roomTypeDescription.length <= 50 ? (
                          <Typography variant="subtitle1">
                            {
                              propertyInfo?.roomTypeList?.filter(
                                (rooms) => rooms?.roomTypeId === selectedRoom
                              )[0]?.roomTypeDescription
                            }
                          </Typography>
                        ) : (
                          <Typography variant="subtitle1">
                            {expandDescription
                              ? propertyInfo?.roomTypeList
                                  ?.filter(
                                    (rooms) =>
                                      rooms?.roomTypeId === selectedRoom
                                  )[0]
                                  ?.roomTypeDescription?.substring(0)
                              : propertyInfo?.roomTypeList
                                  ?.filter(
                                    (rooms) =>
                                      rooms?.roomTypeId === selectedRoom
                                  )[0]
                                  ?.roomTypeDescription?.substring(0, 50)}
                            <Button
                              sx={{ textTransform: "none", fontWeight: "bold" }}
                              onClick={toggleExpand}
                            >
                              {expandDescription
                                ? " Read less"
                                : " ...Read more"}
                            </Button>
                          </Typography>
                        )}
                      </Box>
                    )}
                </Box>
              </CardContent>
            </Box>
          </Card>
          {/* {allRoomTypeMap?.[selectedRoom]?.combos[0]?.rates
            ?.filter((elm, idx) => (moreRatePlans ? true : idx < 2))
            .map((ratePlans) => (
              <Box
                onClick={() => setSelectedRatePlan(ratePlans?.ratePlanId)}
                sx={{
                  p: 1,
                  border: "0.2px solid #FFFFFF",
                  borderRadius: "8px",
                  mt: 1,
                  cursor: "pointer",
                  position: "relative",
                  background:
                    ratePlans?.ratePlanId === selectedRatePlan
                      ? "#161616"
                      : "black",
                }}
              >
                {ratePlans?.ratePlanId === selectedRatePlan && (
                  <IconButton sx={{ position: "absolute", top: 0, right: 0 }}>
                    <CheckCircleOutlineIcon
                      style={{
                        fontSize: "1.5rem",
                        color: "#1ab394",
                      }}
                    />
                  </IconButton>
                )}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    {" "}
                    <Typography variant="h6">
                      {ratePlans?.ratePlanName}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="h6">
                      {currencySymbol} {ratePlans?.actualPrice}
                    </Typography>
                    <Typography variant="caption">
                      *excluding taxes & fees
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="caption">
                  {ratePlans?.ratePlanDescription}
                </Typography>
              </Box>
            ))} */}
          {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => setMoreRatePlans((prev) => !prev)}
            >
              {moreRatePlans ? "Less" : "Show more"}
            </Button>
          </Box> */}
        </Box>
        <Box className="enhance-and-occupancy-container">
          {/* <Box className="enhance-items">
            <Typography variant="h6" sx={{ pl: 1, pr: 1 }}>
              Enhance your stay
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: 1,
                flexWrap: "wrap",
              }}
            >
              {addonsList
                ?.filter((elm, idx) => (moreEnhance ? true : idx < 2))
                ?.map((item) => (
                  <Box sx={{ p: 1, position: "relative" }}>
                    <Card
                      sx={{
                        border: "0.5px solid #FFFFFF",
                        background: selectedAddons.includes(item?.id)
                          ? "#161616"
                          : "black",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddOns(item?.id)}
                    >
                      <CardContent>
                        {selectedAddons.includes(item?.id) && (
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                          >
                            <CheckCircleOutlineIcon
                              style={{
                                fontSize: "1.5rem",
                                color: "#1ab394",
                              }}
                            />
                          </IconButton>
                        )}
                        <Typography variant="body2" align="center">
                          {item?.product_name}
                        </Typography>
                        <Typography variant="body2" align="center">
                          {currencySymbol} {item?.product_price}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={() => setMoreEnhance((prev) => !prev)}
                >
                  {moreEnhance ? "Less" : "Show more"}
                </Button>
              </Box>
            </Box>
          </Box> */}
          <Box className="occupancy-container">
            <Typography variant="h6" sx={{ pl: 1, pr: 1 }}>
              Rooms
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: "8px",
                border: "1px solid #FFFFFF",
                p: 1,
                mt: 2,
                pb: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexFlow: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => handleRooms("dec")}
                  disabled={propertyRooms === 1}
                >
                  <RemoveIcon
                    style={{
                      fontSize: "1.5rem",
                      color: propertyRooms === 1 ? "#808080" : "#FFFFFF",
                    }}
                  />
                </IconButton>

                <Typography variant="h6">
                  {propertyRooms} {propertyRooms < 2 ? "Room" : "Rooms"}
                </Typography>
                <IconButton
                  onClick={() => handleRooms("inc")}
                  disabled={
                    cartItemsFromRedux?.filter(
                      (elm) => elm?.uid === cartItemUid
                    )?.length > 0
                      ? allRoomTypeMap?.[selectedRoom]?.combos[0]
                          ?.availableRooms <=
                        roomsCountInCart -
                          cartItemsFromRedux?.filter(
                            (elm) => elm?.uid === cartItemUid
                          )[0]?.propertyRooms +
                          propertyRooms
                      : allRoomTypeMap?.[selectedRoom]?.combos[0]
                          ?.availableRooms <=
                        roomsCountInCart + propertyRooms
                  }
                >
                  <AddIcon
                    style={{
                      fontSize: "1.5rem",
                      color:
                        cartItemsFromRedux?.filter(
                          (elm) => elm?.uid === cartItemUid
                        )?.length > 0
                          ? allRoomTypeMap?.[selectedRoom]?.combos[0]
                              ?.availableRooms <=
                            roomsCountInCart -
                              cartItemsFromRedux?.filter(
                                (elm) => elm?.uid === cartItemUid
                              )[0]?.propertyRooms +
                              propertyRooms
                            ? "#808080"
                            : "#FFFFFF"
                          : allRoomTypeMap?.[selectedRoom]?.combos[0]
                              ?.availableRooms <=
                            roomsCountInCart + propertyRooms
                          ? "#808080"
                          : "#FFFFFF",
                    }}
                  />
                </IconButton>
              </Box>
              {Array.from(
                { length: propertyRooms },
                (_, index) => index + 1
              ).map((val) => (
                <Box sx={{ mt: 2 }}>
                  <Box>
                    <Typography variant="subtitle1">Room {val}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ width: "45%" }}>
                      <Typography variant="caption">Adult</Typography>

                      <Box
                        sx={{
                          border: "0.5px solid #FFFFFF",
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-around",
                          flexFlow: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => handleAdult("dec", val)}
                          disabled={roomWiseAdults[`Room${val}`] === 1}
                        >
                          <RemoveIcon
                            style={{
                              fontSize: "1.5rem",
                              color:
                                roomWiseAdults[`Room${val}`] === 1
                                  ? "#808080"
                                  : "#FFFFFF",
                            }}
                          />
                        </IconButton>
                        <Typography variant="h6">
                          {roomWiseAdults?.[`Room${val}`]}{" "}
                        </Typography>
                        <IconButton
                          onClick={() => handleAdult("inc", val)}
                          disabled={
                            roomWiseAdults[`Room${val}`] >=
                              allRoomTypeMap[selectedRoom]?.maxOccupancy ||
                            roomWiseAdults[`Room${val}`] +
                              roomWiseChildren[`Room${val}`] >=
                              allRoomTypeMap[selectedRoom]?.maxPossible
                          }
                        >
                          <AddIcon
                            style={{
                              fontSize: "1.5rem",
                              color:
                                roomWiseAdults[`Room${val}`] >=
                                  allRoomTypeMap[selectedRoom]?.maxOccupancy ||
                                roomWiseAdults[`Room${val}`] +
                                  roomWiseChildren[`Room${val}`] >=
                                  allRoomTypeMap[selectedRoom]?.maxPossible
                                  ? "#808080"
                                  : "#FFFFFF",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{ width: "45%" }}>
                      <Typography variant="subtitle1"> </Typography>
                      <Typography variant="caption">
                        Children ({allRoomTypeMap?.[selectedRoom]?.lChildAge}-
                        {allRoomTypeMap?.[selectedRoom]?.rChildAge})
                      </Typography>

                      <Box
                        sx={{
                          border: "0.5px solid #FFFFFF",
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-around",
                          flexFlow: "row",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => handleChildren("dec", val)}
                          disabled={roomWiseChildren[`Room${val}`] === 0}
                        >
                          <RemoveIcon
                            style={{
                              fontSize: "1.5rem",
                              color:
                                roomWiseChildren[`Room${val}`] === 0
                                  ? "#808080"
                                  : "#FFFFFF",
                            }}
                          />
                        </IconButton>
                        <Typography variant="h6">
                          {roomWiseChildren?.[`Room${val}`]}
                        </Typography>

                        <IconButton
                          onClick={() => handleChildren("inc", val)}
                          disabled={
                            roomWiseChildren[`Room${val}`] >=
                              allRoomTypeMap[selectedRoom]?.maxChildren ||
                            roomWiseAdults[`Room${val}`] +
                              roomWiseChildren[`Room${val}`] >=
                              allRoomTypeMap[selectedRoom]?.maxPossible
                          }
                        >
                          <AddIcon
                            style={{
                              fontSize: "1.5rem",
                              color:
                                roomWiseChildren[`Room${val}`] >=
                                  allRoomTypeMap[selectedRoom]?.maxChildren ||
                                roomWiseAdults[`Room${val}`] +
                                  roomWiseChildren[`Room${val}`] >=
                                  allRoomTypeMap[selectedRoom]?.maxPossible
                                  ? "#808080"
                                  : "#FFFFFF",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="enhance-items">
            <Typography variant="h6" sx={{ pl: 1, pr: 1 }}>
              Rates
            </Typography>
            <Box className="rates-collection">
              {renderRatePlans
                ?.filter((elm, idx) => (moreRatePlans ? true : idx < 2))
                .map((ratePlans) => (
                  <Box
                    onClick={() => setSelectedRatePlan(ratePlans?.ratePlanId)}
                    sx={{
                      p: 1,
                      border: "0.2px solid #FFFFFF",
                      borderRadius: "8px",
                      mt: 3,
                      cursor: "pointer",
                      position: "relative",
                      background:
                        ratePlans?.ratePlanId === selectedRatePlan
                          ? "#161616"
                          : "black",
                    }}
                  >
                    {ratePlans?.ratePlanId === selectedRatePlan && (
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CheckCircleOutlineIcon
                          style={{
                            fontSize: "1.5rem",
                            color: "#1ab394",
                          }}
                        />
                      </IconButton>
                    )}

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        {" "}
                        <Typography variant="h6">
                          {ratePlans?.ratePlanName}
                        </Typography>
                      </Box>

                      <Box>
                        {ratePlans?.PromotionThere && (
                          <Chip
                            label={
                              <Typography variant="subtitle1">
                                {(
                                  ((ratePlans?.SelectedRoomCharges -
                                    ratePlans?.SelectedRoomChargesAfterDiscount) *
                                    100) /
                                  ratePlans?.SelectedRoomCharges
                                )?.toFixed(1)}
                                % off
                              </Typography>
                            }
                            variant="outlined"
                          />
                        )}
                        {ratePlans?.PromotionThere && (
                          <Typography
                            variant="h6"
                            sx={{ textDecoration: "line-through" }}
                          >
                            {currencySymbol}{" "}
                            {(
                              ratePlans?.SelectedRoomCharges *
                              currencyConversionFactor
                            )?.toFixed(2)}
                          </Typography>
                        )}
                        <Typography variant="h6">
                          {currencySymbol}{" "}
                          {(
                            ratePlans?.SelectedRoomChargesAfterDiscount *
                            currencyConversionFactor
                          )?.toFixed(2)}
                        </Typography>
                        <Typography variant="caption">
                          *excluding taxes & fees
                        </Typography>
                      </Box>
                    </Box>
                    {/* <Typography variant="body2">
                      {ratePlans?.cancellationPolicyInfo?.policyRule}
                    </Typography> */}
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle1">
                        {ratePlans?.ratePlanDescription ||
                          ratePlans?.cancellationPolicyInfo}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              {renderRatePlans?.length > 2 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{ textTransform: "none", fontWeight: "bold" }}
                    onClick={() => setMoreRatePlans((prev) => !prev)}
                  >
                    {moreRatePlans ? "Less" : "Show more"}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          <Button sx={{ textTransform: "none" }} onClick={() => setStep(2)}>
            Add another room
          </Button>
        </Box> */}

        <Box className="book-now-button" sx={{ mt: 2 }}>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              textTransform: "none",
              backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",

              cursor: "pointer",
            }}
            onClick={handleToAddCartItem}
          >
            <Box sx={{ pl: 3 }}>
              {promotionThere && (
                <Typography variant="h6">
                  {currencySymbol} {selectedRoomChargesAfterDiscount}
                </Typography>
              )}
              <Typography
                variant="h6"
                sx={{
                  position: "relative",
                  display: "inline-block",
                  textDecoration: "none",
                }}
              >
                {currencySymbol} {selectedRoomCharges}
                {promotionThere && (
                  <span
                    style={{
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      borderBottom: "2px solid #FFFFFF",
                      transform: "rotate(-10deg)",
                      transformOrigin: "center",
                    }}
                  ></span>
                )}
              </Typography>
              <Box>
                <Typography variant="caption">
                  excluding taxes & fees
                </Typography>
              </Box>
            </Box>
            <Box sx={{ pr: 3 }}>
              <KeyboardDoubleArrowRightIcon
                style={{ fontSize: "2.8rem", color: "#FFFFFF" }}
              />
            </Box>
          </Box> */}
          <Box sx={{ mb: 2 }}>
            <Button
              variant="custom-button"
              onClick={handleToAddCartItem}
              sx={{ width: "100%", borderRadius: "27px" }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
