import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "Booking Engine",
  Currency: "",
  PropertyInfo: {},
  Params: {},
  cartItem: [],
};

const store = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    saveName: (state, action) => {
      state.Name = action.payload;
    },
    saveCurrency: (state, action) => {
      state.Currency = action.payload;
    },
    savePropertyInfo: (state, action) => {
      state.PropertyInfo = action.payload;
    },
    saveParams: (state, action) => {
      state.Params = action.payload;
    },
    saveCartItem: (state, action) => {
      state.cartItem = action.payload;
    },
  },
});

export const {
  saveName,
  saveCurrency,
  savePropertyInfo,
  saveParams,
  saveCartItem,
} = store.actions;

export default store.reducer;
