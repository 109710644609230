import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiPhoneNumber from "mui-phone-number";
import Footer from "../common/Footer";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import LockIcon from "@mui/icons-material/Lock";
import { Mastercard, Visa, Maestro } from "react-payment-logos/dist/flat";
import { useSelector } from "react-redux";
import { useRef } from "react";
import Checkout from "./stripe/Checkout";

export default function DetailsPage({
  handlePerformBookingOrPayment,
  tcChecked,
  setTcChecked,
  customerFirstName,
  setCustomerFirstName,
  customerLastName,
  setCustomerLastName,
  customerPhone,
  setCustomerPhone,
  customerEmail,
  setCustomerEmail,
  specialRequest,
  setSpecialRequest,
  payAtHotel,
  bookingLoading,
  proprtyInfo,
  allRoomTypeMap,
  currencySymbol,
  currencyConversionFactor,

  //----------stripe checkout ------
  buttonKey,
  buttonToggle,
  stripeMessage,
  successPayment,
  apiPayload,
  addOns,
  disableFunction,
  bookingFee,
  currency,
  partialPaymentStatus,
  partialPaymentPerc,
  partialPayOption,
  pgAccountType,
  pgAccountId,
  hotelId,
  bookingSource,
  propertyAvailability,
  isDefaultHotelPg,
  mpesaPayment,
  proceesingMessage,
  finalPayloadAmount,
}) {
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [tcCheckedErrror, setTcCheckedError] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [cancelationPolicies, setCancelationPolicies] = useState([]);
  useEffect(() => {
    const policyArr = [];
    cartItemsFromRedux?.map((cartItem) => {
      if (
        !policyArr?.includes(
          allRoomTypeMap?.[cartItem?.selectedRoom]?.combos?.[0]?.rates?.filter(
            (rate) => rate?.ratePlanId === cartItem?.selectedRatePlan
          )[0]?.cancellationPolicyInfo?.policyRule
        )
      ) {
        policyArr.push(
          allRoomTypeMap?.[cartItem?.selectedRoom]?.combos?.[0]?.rates?.filter(
            (rate) => rate?.ratePlanId === cartItem?.selectedRatePlan
          )[0]?.cancellationPolicyInfo?.policyRule
        );
      }
    });
    setCancelationPolicies(policyArr);
  }, [cartItemsFromRedux]);

  const continueBookingButton = () => {
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!customerFirstName.trim()) {
      setFirstNameError(true);
      firstNameRef?.current?.focus();
      return;
    }
    if (!customerLastName.trim()) {
      setLastNameError(true);
      lastNameRef?.current?.focus();
      return;
    }
    if (!pattern?.test(customerEmail)) {
      setEmailError(true);
      emailRef?.current?.focus();
      return;
    }
    const phoneNumber = parsePhoneNumberFromString(customerPhone, countryCode);
    if (!phoneNumber || !phoneNumber.isValid()) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }
    // if (!customerPhone) {
    //   setPhoneError(true);
    //   return;
    // }
    if (!tcChecked) {
      setTcCheckedError(true);
      return;
    }
    handlePerformBookingOrPayment();
  };

  const handlePhoneChange = (value, countryData) => {
    const phoneNumber = parsePhoneNumberFromString(value, countryData?.country);

    // Validate the phone number
    if (!phoneNumber || !phoneNumber.isValid()) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
      setCountryCode(phoneNumber.country);
    }

    // Update the state
    setCustomerPhone(value);
  };
  // const [open, setOpen] = useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };
  return (
    <Box className="details-wrapper">
      <Box className="details-item">
        <Box sx={{ mt: 2, p: 1 }}>
          <Typography variant="h6">Enter guest details</Typography>
        </Box>
        <Box className="basic-detail">
          <Box className="basicItem">
            <Typography variant="subtitle1">First name*</Typography>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              fullWidth
              value={customerFirstName}
              onChange={(e) => {
                setCustomerFirstName(e?.target?.value);
                setFirstNameError(false);
              }}
              error={firstNameError}
              inputRef={firstNameRef}
              autoFocus={true}
              helperText={
                firstNameError ? (
                  <span style={{ color: "red" }}>First name is mandatory*</span>
                ) : null
              }
            />
          </Box>
          <Box className="basicItem">
            <Typography variant="subtitle1">Last name*</Typography>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              fullWidth
              value={customerLastName}
              onChange={(e) => {
                setCustomerLastName(e?.target?.value);
                setLastNameError(false);
              }}
              error={lastNameError}
              inputRef={lastNameRef}
              helperText={
                lastNameError ? (
                  <span style={{ color: "red" }}>Last name is mandatory*</span>
                ) : null
              }
            />
          </Box>
          <Box className="basicItem">
            <Typography variant="subtitle1">Email*</Typography>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              fullWidth
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e?.target?.value);
                setEmailError(false);
              }}
              error={emailError}
              inputRef={emailRef}
              helperText={
                emailError ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid email id*
                  </span>
                ) : null
              }
            />
          </Box>
          <Box className="basicItem">
            <Typography variant="subtitle1">Phone*</Typography>
            <MuiPhoneNumber
              defaultCountry={proprtyInfo?.country === "India" ? "in" : "us"}
              fullWidth
              // margin="normal"
              variant="outlined"
              value={customerPhone}
              size="small"
              // onChange={(e) => {
              //   setCustomerPhone(e);
              //   setPhoneError(false);
              // }}
              onChange={(value, countryData) =>
                handlePhoneChange(value, countryData)
              }
              // onChange={(e) => {
              //   setContact(e);
              //   setErrorSecond(false);
              // }}
              helperText={
                phoneError ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid phone number*
                  </span>
                ) : null
              }
              error={phoneError}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">Special request</Typography>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            fullWidth
            value={specialRequest}
            onChange={(e) => setSpecialRequest(e?.target?.value)}
          />
        </Box>

        <Box
          sx={{
            mt: 3,
            height: "8rem",
            width: "100%",
            overflow: "scroll",
            // mb: 3,
            p: 1,
            border: `1px solid #fff`,
            borderRadius: "4px",
          }}
        >
          <Typography
            align="center"
            variant="subtitle2"
            sx={{ fontSize: "1.3rem" }}
          >
            Terms and Conditions
          </Typography>
          {proprtyInfo?.hotelTNC?.length > 0 ? (
            proprtyInfo?.hotelTNC?.map((tnc) =>
              tnc
                ?.split("\n")
                ?.map((item) => (
                  <Typography variant="subtitle2">{item}</Typography>
                ))
            )
          ) : cancelationPolicies?.length > 0 ? (
            cancelationPolicies?.map((policy) => (
              <Typography variant="subtitle2">{policy}</Typography>
            ))
          ) : (
            <Typography variant="subtitle2">
              No terms and conditions.
            </Typography>
          )}

          {/* <Box sx={{ mt: 2, display: "flex", alignItems: "center", mb: 2 }}>
            <Checkbox
              checked={tcChecked}
              style={{ color: tcCheckedErrror ? "red" : "white" }}
              onClick={() => {
                setTcChecked((prev) => !prev);
                setTcCheckedError(false);
              }}
            />
            <Typography variant="subtitle1">
              I agree to the Terms & Conditions
            </Typography>
          </Box> */}
        </Box>
        <Box sx={{ mt: 2, display: "flex", alignItems: "center", mb: 2 }}>
          <Checkbox
            checked={tcChecked}
            style={{ color: tcCheckedErrror ? "red" : "white" }}
            onClick={() => {
              setTcChecked((prev) => !prev);
              setTcCheckedError(false);
            }}
          />
          <Typography variant="subtitle1">
            I agree to the Terms & Conditions*
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          {!payAtHotel &&
            proprtyInfo?.country !== "India" &&
            ((proprtyInfo?.country === "Malaysia" && isDefaultHotelPg) ||
              proprtyInfo?.country !== "Malaysia") &&
            !mpesaPayment && (
              <Checkout
                buttonKey={buttonKey}
                stripeMessage={stripeMessage}
                buttonToggle={buttonToggle}
                successPayment={successPayment}
                apiPayload={apiPayload}
                addOns={addOns}
                payAtHotel={payAtHotel}
                disableFunction={disableFunction}
                currency={currency}
                partialPaymentStatus={partialPaymentStatus}
                partialPaymentPerc={partialPaymentPerc}
                partialPayOption={partialPayOption}
                pgAccountType={pgAccountType}
                pgAccountId={pgAccountId}
                proprtyInfo={proprtyInfo}
                hotelId={hotelId}
                bookingSource={bookingSource}
                propertyAvailability={propertyAvailability}
                finalPayloadAmount={finalPayloadAmount}
              />
            )}
        </Box>

        {!payAtHotel && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Box>
              <Mastercard height={28} />
              <Visa height={28} />
              <Maestro height={28} />
            </Box>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="custom-button"
            sx={{ width: "100%", borderRadius: "27px" }}
            onClick={continueBookingButton}
            disabled={bookingLoading || !tcChecked}
          >
            {proceesingMessage
              ? proceesingMessage
              : payAtHotel
              ? `Book now`
              : ` Continue to make payment of ${currencySymbol}${(
                  finalPayloadAmount * currencyConversionFactor
                )?.toFixed(2)}`}

            {bookingLoading ? (
              <CircularProgress size="0.875rem" />
            ) : (
              !payAtHotel && <LockIcon size="0.875rem" />
            )}
          </Button>
        </Box>
      </Box>
      <Box className="footer-container-detailpage">
        <Footer />
      </Box>
    </Box>
  );
}
