import React from "react";
import { CardMedia } from "@mui/material";

import defaultHotelImage from "../asset/default_img.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/PropertyImageSliding.scss";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

export default function PropertyImageSlider({ images }) {
  const NextArrow = (props) => (
    <div className="arrow next" onClick={props.onClick}>
      <ArrowCircleRightIcon />
    </div>
  );

  const PrevArrow = (props) => (
    <div className="arrow prev" onClick={props.onClick}>
      <ArrowCircleLeftIcon />
    </div>
  );
  const settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {images
        ?.sort((a, b) => a.orderId - b.orderId)
        ?.map((image, index) => (
          <CardMedia
            key={index}
            className="cardmedia"
            component="img"
            image={image?.imageUrl || defaultHotelImage}
            alt={`Image ${index + 1}`}
          />
        ))}
    </Slider>
  );
}
