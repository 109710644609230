import React, { useEffect } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";

import EastIcon from "@mui/icons-material/East";
import { useSelector } from "react-redux";

export default function PaginationComponent({
  step,
  setStep,
  stepNames,
  selectedRoom,
  propertyAvailability,
}) {
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const handleFarwardMenu = (item) => {
    if (item <= step) {
      if (+item === 3) {
        // if (selectedRoom) {
        //   return false;
        // }
        return true;
      } else {
        return false;
      }
    } else {
      if (+item === 2 && propertyAvailability) {
        return false;
      } else if (
        (+item === 4 || +item === 5) &&
        cartItemsFromRedux?.length > 0
      ) {
        return false;
      }
      // else if (item == 3 && selectedRoom) {
      //   return false;
      // }
      return true;
    }
  };
  const handleBackStep = (step) => {
    if (step === 3) {
      if (selectedRoom) {
        setStep(3);
      }
    } else {
      setStep(step);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {step > 1 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "30%",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ cursor: "pointer" }}
            onClick={() => handleBackStep(step - 1)}
          >
            {stepNames[step - 1]}
          </Typography>
          <EastIcon
            style={{
              fontSize: "1rem",
              color: "#FFFFFF",
              margin: "0px 4px 0px 4px",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
              WebkitBackgroundClip: "text",
              color: "transparent",
              display: "inline-block",
            }}
          >
            {stepNames[step]}
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="subtitle1"
          sx={{
            background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            display: "inline-block",
          }}
        >
          Dates
        </Typography>
      )}

      <Box>
        <FormControl variant="standard">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={step}
            onChange={(e) => {
              // console.log("e.target.value", e.target.value);
              if (+e?.target?.value === 3) {
                if (selectedRoom) {
                  setStep(+e?.target?.value);
                }
              } else {
                setStep(+e?.target?.value);
              }
            }}
            label="Age"
            sx={{
              // width: 100,
              height: 30,
              color: "#fff",
            }}
          >
            {Object.keys(stepNames).map((elm) => (
              <MenuItem value={elm} disabled={handleFarwardMenu(elm)}>
                {stepNames[elm]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
