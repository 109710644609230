import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useEffect } from "react";
import loader from "../../asset/loader3.svg";
import CheckoutForm from "./CheckoutForm";
import { baseUrlDjango, stripePublicKey } from "../../utils/constants";
import { STRIPE_DIRECT_CHARGE_TYPE } from "../../common/common";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${stripePublicKey}`);

function Checkout({
  buttonKey,
  buttonToggle,
  stripeMessage,
  successPayment,
  apiPayload,
  addOns,
  payAtHotel,
  disableFunction,
  currency,
  partialPaymentStatus,
  partialPaymentPerc,
  partialPayOption,
  pgAccountType,
  pgAccountId,
  proprtyInfo,
  hotelId,
  bookingSource,
  propertyAvailability,
  finalPayloadAmount,
}) {
  useEffect(() => {
    if (
      proprtyInfo?.hotelCurrency !== undefined &&
      proprtyInfo?.hotelCurrency !== null
    ) {
      fetchClientSecret();
    }
  }, [
    proprtyInfo,
    partialPayOption,
    apiPayload?.paymentDetails?.sellRate,
    partialPaymentStatus,
    finalPayloadAmount,
  ]);

  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  async function fetchClientSecret() {
    let finalUpdatedAmount = finalPayloadAmount;
    if (
      partialPaymentStatus === "ENABLED" &&
      partialPayOption === "pay_partial" &&
      partialPaymentPerc > 0
    ) {
      finalUpdatedAmount = Number(
        finalUpdatedAmount * (partialPaymentPerc / 100)
      );
    }
    let request_payload = {
      hotel_id: `${hotelId}`,
      amount: Number(finalUpdatedAmount).toFixed(2),
      checkin: apiPayload?.checkin,
      checkout: apiPayload.checkout,
      hotelName: proprtyInfo.propertyName,
      currency: proprtyInfo.hotelCurrency,
      booking_id: null,
      module_source: "BE_PAYMENT",
      booking_source: bookingSource,
      be_version: "2.0",
    };
    if (pgAccountType === STRIPE_DIRECT_CHARGE_TYPE && pgAccountId !== "") {
      request_payload = { ...request_payload, stripe_account: pgAccountId };
      const response = await fetch(
        `${baseUrlDjango}/api/v2/payments/createDirectPaymentIntent/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request_payload),
        }
      );
      let data = await response.json();
      // console.log("datadata..", data, propertyAvailability?.checkin);
      setClientSecret(data?.client_secret_key);
      setPaymentIntentId(data?.id);
    } else {
      const response = await fetch(
        `${baseUrlDjango}/api/v2/payments/createPaymentIntent/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request_payload),
        }
      );
      let data = await response.json();
      setClientSecret(data?.client_secret_key);
      setPaymentIntentId(data.id);
    }
  }

  if (clientSecret === "")
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ height: "auto" }}
      >
        <img width={50} src={loader} alt="" />
      </div>
    );
  return clientSecret !== "" &&
    clientSecret !== undefined &&
    clientSecret !== null ? (
    <Elements
      stripe={
        pgAccountType === STRIPE_DIRECT_CHARGE_TYPE && pgAccountId !== ""
          ? loadStripe(`${stripePublicKey}`, {
              stripeAccount: pgAccountId,
            })
          : loadStripe(`${stripePublicKey}`)
      }
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: "#808080",
            colorBackground: "#000",
            colorText: "#ffffff",
            colorDanger: "#f44336",
            fontWeightNormal: "700",
          },
          rules: {
            ".Input": {
              border: "1px solid #fff",
            },
          },
        },
      }}
    >
      <CheckoutForm
        buttonKey={buttonKey}
        buttonToggle={buttonToggle}
        successPayment={successPayment}
        stripeMessage={stripeMessage}
        apiPayload={apiPayload}
        addOns={addOns}
        payAtHotel={payAtHotel}
        disableFunction={disableFunction}
        paymentIntentId={paymentIntentId}
        currency={currency}
        pgAccountType={pgAccountType}
        pgAccountId={pgAccountId}
        finalPayloadAmount={finalPayloadAmount}
      />
    </Elements>
  ) : (
    <p className="mt-2" style={{ color: "#f44336", fontSize: "0.8rem" }}>
      The stripeCheckout failed to load.{" "}
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => fetchClientSecret()}
      >
        Reload again?
      </span>
    </p>
  );
}

export default Checkout;
