import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { channelLogoMap } from "../common/common";
import stamp from "../asset/stamp.png";
import { Google } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
export default function OtaRatesComparison({
  rateCompareData,
  selectedRoom,
  currencyConversionFactor,
  currencySymbol,
}) {
  const [otaRates, setOtaRates] = useState([]);
  useEffect(() => {
    if (rateCompareData) {
      const arr = [];
      Object?.keys(
        rateCompareData?.pricingRoomMap?.[selectedRoom]?.sourceRoomRateMap
      )?.map((ota) => {
        if (ota === "google") {
          arr.push({
            name: "google",
            value:
              rateCompareData?.pricingRoomMap?.[selectedRoom]?.sourceRoomRateMap
                ?.google,
          });
        } else {
          if (
            rateCompareData?.pricingRoomMap?.[selectedRoom]
              ?.sourceRoomRateMap?.[ota] >
            rateCompareData?.pricingRoomMap?.[selectedRoom]?.sourceRoomRateMap
              ?.google
          ) {
            const obj = { name: ota };
            obj.value =
              rateCompareData?.pricingRoomMap?.[
                selectedRoom
              ]?.sourceRoomRateMap?.[ota];
            arr.push(obj);
          }
        }
      });
      setOtaRates(arr);
      console.log(arr);
    }
  }, [rateCompareData]);
  return (
    <Box className="otarate-container">
      <Box className="ota-stamp">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img src={stamp} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Typography variant="h5" align="center" sx={{ fontSize: "95%" }}>
              Guaranteed
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="otalogo-container">
        <Box className="otalogo-contents">
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 4,
              mb: 1,
            }}
          >
            <Box>
              <Typography variant="subtitle1">Your price</Typography>
              <Typography variant="subtitle2">as seen on</Typography>
              <img height="32px" alt="GOOGLE" src={channelLogoMap["GOOGLE"]} />
            </Box>
            <Box sx={{ mt: 6 }}>
              <Typography variant="h5">
                {currencySymbol}{" "}
                {(
                  rateCompareData?.pricingRoomMap?.[selectedRoom]
                    ?.sourceRoomRateMap?.["google"] * currencyConversionFactor
                ).toFixed(2)}{" "}
              </Typography>
              <Typography variant="caption">
                {currencySymbol}{" "}
                {(
                  (rateCompareData?.pricingRoomMap?.[selectedRoom]
                    ?.sourceRateMap?.["google"]?.preTaxRate +
                    rateCompareData?.pricingRoomMap?.[selectedRoom]
                      ?.sourceRateMap?.["google"]?.taxRate) *
                  currencyConversionFactor
                ).toFixed(2)}{" "}
                including taxes and fees
              </Typography>
            </Box>
          </Box>
          {/* {Object.keys(
            rateCompareData?.pricingRoomMap?.[selectedRoom]?.sourceRoomRateMap
          )
            ?.filter((item) => item !== "google")
            ?.map((ota) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  // p: 1,
                  gap: 4,
                }}
              >
                <Box>
                  {channelLogoMap[ota?.toUpperCase()] ? (
                    <img
                      height="20px"
                      alt={ota}
                      src={channelLogoMap[ota?.toUpperCase()]}
                    />
                  ) : (
                    <Typography variant="subtitle2">{ota}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {currencySymbol}{" "}
                    {(
                      rateCompareData?.pricingRoomMap?.[selectedRoom]
                        ?.sourceRoomRateMap?.[ota] * currencyConversionFactor
                    ).toFixed(2)}{" "}
                  </Typography>
                </Box>
              </Box>
            ))} */}

          {otaRates?.length > 1 ? (
            otaRates
              ?.filter((item) => item?.name !== "google")
              .map((ota) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    // p: 1,
                    gap: 4,
                  }}
                >
                  <Box>
                    {channelLogoMap[ota?.name?.toUpperCase()] ? (
                      <img
                        height="20px"
                        alt={ota}
                        src={channelLogoMap[ota?.name?.toUpperCase()]}
                      />
                    ) : (
                      <Typography variant="subtitle2">{ota?.name}</Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {currencySymbol}{" "}
                      {(ota?.value * currencyConversionFactor).toFixed(2)}{" "}
                    </Typography>
                  </Box>
                </Box>
              ))
          ) : (
            <Box>
              <Typography variant="subtitle2">Best room guaranteed</Typography>
              <Typography variant="subtitle2">for direct bookings</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
