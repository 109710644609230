import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CircularProgress from "@mui/material/CircularProgress";

import { createGlobalStyle, ThemeProvider } from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import Footer from "../common/Footer";
import { useDispatch } from "react-redux";
import { saveCartItem } from "../redux/slice/slice";
let count = 0;

export default function DateSelectionPage({
  dateRange,
  setDateRange,
  adults,
  setAdults,
  children,
  setChildren,
  gettingPropertyAvailability,
  gettingRateComparison,
  nextLoader,
  proprtyInfo,
  setPropertyAvailability,
  setSelectedRoom,
  checkin,
  num_nights,
  setRateCompareData,
  setSelectedRatePlan,
  setCartItemUid,
  setEditableRateItem,
  setIsEditCardItem,
}) {
  let windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const customTheme = {
    dateRange: {
      background: "black",
      color: "white",
      borderRadius: "8px",
      width: "100%",
      containerWidth: windowWidth < 960 ? "100%" : "960px",
      padding: "10px",
      selectColor: "#1AAC9B",
      snfbackground: "#1AAC9B",
    },
  };

  const GlobalStyles = createGlobalStyle`
  .rdrDateRangeWrapper {
    background: ${(props) => props.theme.dateRange.background};
    color: ${(props) => props.theme.dateRange.color};
    border-radius: ${(props) => props.theme.dateRange.borderRadius};
    width: ${(props) => props.theme.dateRange.containerWidth};
    padding: ${(props) => props.theme.dateRange.padding};
  }
  .rdrMonthsVertical{
    width: ${(props) => props.theme.dateRange.width};
  }
  .rdrMonthAndYearWrapper{
    width: ${(props) => props.theme.dateRange.width};
  }
  .rdrDateDisplayWrapper{
    width: ${(props) => props.theme.dateRange.width};
  }
  .rdrMonth{
    width: ${(props) => props.theme.dateRange.width};
  }
  .rdrDateDisplayWrapper{
    display:none;
  }
  .rdrDayNumber span{
    color:${(props) => props.theme.dateRange.color};
    font-weight:bold;
  }
  .rdrDayDisabled .rdrDayNumber span{
    color:#454545
  }
  // span.rdrInRange{
  //   color:${(props) => props.theme.dateRange.selectColor};
  //   background: ${(props) => props.theme.dateRange.snfbackground};
  // }
  .rdrDayDisabled{
    background: ${(props) => props.theme.dateRange.background};
  }
  .rdrMonthAndYearPickers select{
    color:#fff;
    font-weight:bold;
  }


  // .rdrStartEdge{
  //   background: ${(props) => props.theme.dateRange.snfbackground};
  // }
  // .rdrEndEdge{
  //   background: ${(props) => props.theme.dateRange.snfbackground};
  // }
  // .rdrDayToday .rdrDayNumber span:after {
  //   background: ${(props) => props.theme.dateRange.snfbackground};
  // }
  // .rdrDay{
  //   color: ${(props) => props.theme.dateRange.snfbackground};
  // }
  
  
  
`;
  const rangeColors = ["#1AAC9B"];
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   maxWidth: "960px",
  //   with: "100%",
  //   bgcolor: "background.paper",
  //   boxShadow: 24,
  // };
  //open and close date picker
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    p: "0.1rem",
    width: windowWidth < 960 ? "90%" : "fit-content",
    // maxWidth: "fit-content",
    bgcolor: "#454545",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal((prev) => !prev);
    setMinDate(new Date());
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);

    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  //---------------------------------
  const handleChildAdult = (guest, operation) => {
    if (guest === "adult") {
      if (operation === "inc") {
        setAdults(adults + 1);
      }
      if (operation === "dec" && adults > 1) {
        setAdults(adults - 1);
      }
    }
    if (guest === "child") {
      if (operation === "inc") {
        setChildren(children + 1);
      }
      if (operation === "dec" && children > 0) {
        setChildren(children - 1);
      }
    }
  };

  const [minDate, setMinDate] = useState(new Date());
  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    if (count % 2 === 0) {
      setDateRange([ranges.selection]);
      setMinDate(startDate);
      count++;
    } else {
      if (startDate < endDate) {
        setDateRange([{ startDate, endDate, key: "selection" }]);
        setOpenModal(false);
        count++;
      } else {
        console.error("Invalid date range");
      }
    }
  };
  const handleCloseCalenderModal = () => {
    if (
      dateRange[0]?.startDate?.getTime() === dateRange[0]?.endDate?.getTime()
    ) {
      const nextDay = new Date(dateRange[0]?.endDate);
      nextDay.setDate(nextDay.getDate() + 1);

      setDateRange([
        {
          startDate: dateRange[0]?.startDate,
          endDate: nextDay,
          key: "selection",
        },
      ]);
    }
    setOpenModal(false);
  };
  const handleNextButton = () => {
    if (proprtyInfo) {
      gettingPropertyAvailability(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate,
        false
      );
      if (checkin && num_nights) {
        gettingRateComparison(
          dateRange[0]?.startDate,
          dateRange[0]?.endDate,
          1
        );
      }
    }
  };
  useEffect(() => {
    dispatch(saveCartItem([]));
    setPropertyAvailability(null);
    setSelectedRoom("");
    setRateCompareData(null);
    setSelectedRatePlan("");
    setCartItemUid("");
    setEditableRateItem("");
    setIsEditCardItem(false);
  }, []);
  return (
    <Box className="calender-wrapper">
      <Box className="calender-item">
        <Card
          variant="outlined"
          sx={{
            background: "#161616",
            // height: "90%",
            width: "100%",
            pt: 2,
            borderRadius: "8px",
            // pb: 6,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Typography variant="h6" align="center">
              Arrival
            </Typography>
            <Typography variant="h6" align="center">
              Departure
            </Typography>
          </Box>

          {/* <Stack spacing={4} className="position-relative" sx={{ m: 2 }}>
            <ClickAwayListener onClickAway={handleClickAway}> */}
          <Box sx={{ position: "relative", m: 2 }}>
            <Box
              sx={{
                border: "0.5px solid #FFFFFF",
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-evenly",
                flexFlow: "row",
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Typography variant="h2" align="center">
                  {moment(dateRange[0]?.startDate)?.format("Do")}
                </Typography>
                <Typography variant="h6" align="center">
                  {moment(dateRange[0]?.startDate)?.format("MMM, YYYY")}
                </Typography>
              </Box>
              <Box>
                <Divider
                  align="center"
                  orientation="vertical"
                  width="0.5px"
                  style={{ background: "#FFFFFF", margin: "0 8px" }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <Typography variant="h2" align="center">
                  {moment(dateRange[0]?.endDate)?.format("Do")}
                </Typography>
                <Typography variant="h6" align="center">
                  {moment(dateRange[0]?.endDate)?.format("MMM, YYYY")}
                </Typography>
              </Box>
            </Box>
            {/* {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <ThemeProvider theme={customTheme}>
                          <GlobalStyles />

                          <DateRange
                            editableDateInputs={true}
                            onChange={handleDateChange}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            minDate={new Date()}
                            months={2}
                          />
                        </ThemeProvider>
                      </Box>
                    </Stack>
                  </Box>
                ) : null} */}
          </Box>
          {/* </ClickAwayListener>
          </Stack> */}

          <Modal
            open={openModal}
            // onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styles}>
              <CancelIcon
                onClick={handleCloseCalenderModal}
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  cursor: "pointer",
                  color: "#FFFFFF",
                }}
              />
              <ThemeProvider theme={customTheme}>
                <GlobalStyles />

                <DateRange
                  editableDateInputs={true}
                  onChange={handleDateChange}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  minDate={minDate}
                  months={windowWidth < 960 ? 1 : 2}
                  rangeColors={rangeColors}
                  direction="horizontal"
                />
              </ThemeProvider>
            </Box>
          </Modal>

          {/* <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
            <Typography variant="h6" align="center">
              Adults
            </Typography>
            <Typography variant="h6" align="center">
              Children
            </Typography>
          </Box> */}
          {/* <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              sx={{
                m: 2,
                border: "0.5px solid #FFFFFF",
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-evenly",
                flexFlow: "row",
                width: "50%",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => handleChildAdult("adult", "dec")}>
                <RemoveIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
              </IconButton>

              <Typography variant="h4">{adults}</Typography>
              <IconButton onClick={() => handleChildAdult("adult", "inc")}>
                <AddIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                m: 2,
                border: "0.5px solid #FFFFFF",
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-evenly",
                flexFlow: "row",
                width: "50%",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => handleChildAdult("child", "dec")}>
                <RemoveIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
              </IconButton>

              <Typography variant="h4">{children}</Typography>
              <IconButton onClick={() => handleChildAdult("child", "inc")}>
                <AddIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
              </IconButton>
            </Box>
          </Box> */}

          {/* <Box sx={{ m: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Promo code ?
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Promo code"
              variant="outlined"
              value={promocode}
              onChange={(e) => setPromocode(e.target.value)}
            />
          </Box> */}

          <Box sx={{ m: 2 }}>
            <Button
              variant="custom-button"
              onClick={handleNextButton}
              sx={{ width: "100%", borderRadius: "27px" }}
            >
              {nextLoader ? (
                <CircularProgress size="1.5rem" sx={{ color: "white" }} />
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Card>
        <Box className="footer-container">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
