import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function Footer() {
  return (
    <Box className="footer-page">
      <Typography variant="caption" sx={{ color: "gray" }} align="center">
        Powered by Stayflexi
      </Typography>
    </Box>
  );
}
