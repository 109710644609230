import { baseUrl } from "./constants";

export const sendSlackNotification = (message) => {
  fetch(`${baseUrl}/core/api/v1/be/send-payment-fail-notif`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  }).then((res) => res.json());
};
