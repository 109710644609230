import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENV, baseUrl, baseUrlDjango } from "../../utils/constants";
import { sendSlackNotification } from "../../utils/utils";
import { STRIPE_DIRECT_CHARGE_TYPE } from "../../common/common";
import { handleAmplitude } from "../../amplitude/AmplitudeUtils";

const CheckoutForm = ({
  buttonKey,
  buttonToggle,
  stripeMessage,
  successPayment,
  apiPayload,
  addOns,
  payAtHotel,
  disableFunction,
  paymentIntentId,
  currency,
  pgAccountType,
  pgAccountId,
  finalPayloadAmount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const stripeButton = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    buttonKey && handleSubmit();
  }, [buttonKey]);

  const handleSubmit = async (event) => {
    console.log("finalPayloadAmount", finalPayloadAmount);
    if (!stripe || !elements) {
      return;
    }

    stripeMessage(true);
    apiPayload["isEnquiry"] = true;
    if (apiPayload["customerDetails"].address === "undefined")
      apiPayload["customerDetails"].address = "";
    if (apiPayload["customerDetails"].city === "undefined")
      apiPayload["customerDetails"].city = "";
    if (apiPayload["customerDetails"].country === "undefined")
      apiPayload["customerDetails"].country = "";
    if (apiPayload["customerDetails"].state === "undefined")
      apiPayload["customerDetails"].state = "";
    if (apiPayload["customerDetails"].zipcode === "undefined")
      apiPayload["customerDetails"].zipcode = "";

    let res = "";
    disableFunction(true);
    // if (addOns?.length != 0) {
    //   let payloadPOSOrders = [];
    //   let shopsSelected = [];
    //   for (let i = 0; i < addOns?.length; i++) {
    //     if (!shopsSelected.includes(addOns[i]?.shop_id)) {
    //       shopsSelected.push(addOns[i]?.shop_id);
    //     }
    //   }
    //   for (let shopInd = 0; shopInd < shopsSelected.length; shopInd++) {
    //     let tempPositems = [];
    //     let shopAmount = 0;
    //     for (let itemInd = 0; itemInd < addOns?.length; itemInd++) {
    //       if (addOns[itemInd].shop_id == shopsSelected[shopInd]) {
    //         let tempAddOn = addOns[itemInd].product;
    //         let addOnTax = tempAddOn.tax_category.percentage;
    //         let tempAddOnTaxes =
    //           (tempAddOn.product_price * addOns[itemInd].qty * addOnTax) / 100;
    //         let tempTotal =
    //           tempAddOn.product_price * addOns[itemInd].qty + tempAddOnTaxes;
    //         tempPositems.push({
    //           product_id: tempAddOn.id,
    //           product_price: tempAddOn.product_price,
    //           product_qty: addOns[itemInd].qty,
    //           product_total: tempTotal,
    //           product_name: tempAddOn.product_name,
    //           product_desc: tempAddOn.product_desc,
    //           product_tax_percent: addOnTax,
    //           tax_id: -1,
    //         });
    //         shopAmount = shopAmount + tempTotal;
    //       }
    //     }
    //     payloadPOSOrders.push({
    //       items: tempPositems,
    //       shop_id: shopsSelected[shopInd],
    //       amount: shopAmount,
    //     });
    //   }
    // }

    if (paymentIntentId !== "") {
      let updated_payment_check = {
        hotel_id: apiPayload?.hotelId,
        stripe_payment_intent_id: paymentIntentId,
        metadata: {
          hotel_id: apiPayload?.hotelId,
          checkin: apiPayload?.checkin,
          checkout: apiPayload?.checkout,
          roomtype_name: apiPayload?.roomStays[0]?.roomTypeName,
          roomtype_id: apiPayload?.roomStays[0]?.roomTypeId,
          rateplan_name: "",
          rateplan_id: apiPayload?.roomStays[0]?.ratePlanId,
          customer_name:
            apiPayload["customerDetails"]?.firstName +
            apiPayload["customerDetails"]?.lastName,
          customer_phone: apiPayload["customerDetails"]?.phoneNumber,
          customer_email: apiPayload["customerDetails"]?.emailId,
          booking_source: apiPayload?.bookingSource,
          promo_code: apiPayload?.appliedPromocode,
        },
      };
      if (pgAccountType === STRIPE_DIRECT_CHARGE_TYPE && pgAccountId !== "") {
        updated_payment_check = {
          ...updated_payment_check,
          stripe_account: pgAccountId,
        };
      }
      const response = await fetch(
        `${baseUrlDjango}/api/v2/payments/updatePaymentIntent/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updated_payment_check),
        }
      ).then((res) => console.log(res));
    }
    res = await fetch(`${baseUrl}/core/api/v1/be/perform-booking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });
    if (!res.ok) {
      return res.json().then((json) => {
        throw json;
      });
    }
    const bookingResponse = await res.json();

    if (bookingResponse.message?.toUpperCase() === "SUCCESS") {
      const booking_id = bookingResponse.bookingId;

      let bookingPayData = {
        booking_amount: currency + " " + finalPayloadAmount,
        booking_id: booking_id,
        booking_source: apiPayload?.bookingSource,
        guest_email: apiPayload["customerDetails"]?.emailId,
        guest_name:
          apiPayload["customerDetails"]?.firstName +
          " " +
          apiPayload["customerDetails"]?.lastName,
        guest_phone: apiPayload["customerDetails"]?.phoneNumber,
        payment_gateway: "Stripe",
      };

      localStorage.setItem("paymentGateway", "STRIPE");

      const data = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (data.error) {
        sendSlackNotification(bookingPayData);
        const res = await fetch(
          `${baseUrl}/core/api/v1/be/post-be-booking/?bookingId=${booking_id}`,
          {
            method: "GET",
          }
        );

        setErrorMessage(data?.error?.message);
        disableFunction(false);
        stripeMessage(false);
        handleAmplitude(
          { errorMessage: data?.error?.message },
          "STRIPE PAYMENT ERROR",
          apiPayload?.hotelId
        );
      } else {
        setErrorMessage("");
        stripeMessage(false);

        switch (data?.paymentIntent?.status) {
          case "succeeded":
            successPayment(data?.paymentIntent?.id, booking_id);

            break;

          case "processing":
            break;

          case "requires_payment_method":
            break;

          default:
            break;
        }
        handleAmplitude(
          { stripeStage: data?.paymentIntent?.id },
          "STRIPE PAYMENT IS ON THE WAY",
          apiPayload?.hotelId
        );
      }
      buttonToggle();
    } else {
      handleAmplitude(apiPayload, "STRIPE PAYMENT FAILED", apiPayload?.hotelId);
      navigate(`/booking-failure/${apiPayload.hotelId}/not_available`);
    }
  };

  return (
    <div className="border-1 p-2" style={{ borderRadius: "10px" }}>
      <PaymentElement className="payment" />
      {/* <button
        className="btn d-none"
        ref={stripeButton}
        onClick={handleSubmit}
        disabled={!stripe}
      >
        Submit
      </button> */}
      {errorMessage && (
        <p
          style={{
            color: "#f44336",
            fontSize: "1rem",
            fontWeight: "700",
          }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default CheckoutForm;
