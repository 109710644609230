import React, { useState } from "react";
import { CardMedia, Modal, Box, IconButton } from "@mui/material";

import defaultHotelImage from "../asset/default_img.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/PropertyImageSliding.scss";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function PropertyCategorySliding({ images }) {
  const NextArrow = (props) => (
    <div className="arrow next" onClick={props.onClick}>
      <ArrowCircleRightIcon />
    </div>
  );

  const PrevArrow = (props) => (
    <div className="arrow prev" onClick={props.onClick}>
      <ArrowCircleLeftIcon />
    </div>
  );
  const settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
    background: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (window.innerWidth > 600) {
      setOpen(true);
    }
  };
  return (
    <>
      <Slider {...settings}>
        {images
          ?.sort((a, b) => a.orderId - b.orderId)
          ?.map((image, index) => (
            <Box
              sx={{
                position: "relative",
                paddingTop: "calc(100% * 2 / 3)",
              }}
            >
              <CardMedia
                onClick={handleOpen}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                component="img"
                image={image?.imageUrl || defaultHotelImage}
                alt="hotel image"
              />
            </Box>
          ))}
      </Slider>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ width: "70%" }}>
            <Slider {...settings}>
              {images
                ?.sort((a, b) => a.orderId - b.orderId)
                ?.map((image, index) => (
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "calc(100% * 2 / 3)",
                    }}
                  >
                    <CardMedia
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      component="img"
                      image={image?.imageUrl || defaultHotelImage}
                      alt="hotel image"
                    />
                  </Box>
                ))}
            </Slider>
          </Box>
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              fontSize: "1.5rem",
              color: "#FFFFFF",
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
}
