import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import defaultHotelImage from "../asset/default_img.jpg";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { saveCartItem } from "../redux/slice/slice";
import PropertyImageSlider from "./PropertyImageSlider";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PolicyIcon from "@mui/icons-material/Policy";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../common/Footer";
let timer = 1;
export default function SummaryPage({
  setStep,
  allRoomTypeMap,
  propertyAvailability,
  currencySymbol,
  setSelectedRoom,
  setSelectedRatePlan,
  setIsEditCardItem,
  bookingDaysCount,
  setEditableRateItem,
  setRoomStays,
  setgrandTotalRoomCharges,
  grandTotalRoomChargesAfterDiscount,
  setgrandTotalRoomChargesAfterDiscount,
  bookingFee,
  setBookingFee,
  setPayAtHotel,
  handleRoomStaysPromoCodeValidation,
  setGrandTotalTaxes,
  setGrandTotalTaxesAfterDiscount,
  currencyConversionFactor,
  grandTotalTaxesAfterDiscount,
  userPromoDiscount,
  promocode,
  setPromocode,
  setNonPromograndTotalRoomChargesAfterDiscount,
  setNonPromoGrandTotalTaxesAfterDiscount,
  setCartItemUid,
  promoInfo,
  nonPromograndTotalRoomChargesAfterDiscount,
  nonPromoGrandTotalTaxesAfterDiscount,
  setUserPromoDiscount,
  setPromoInfo,
  promoApplyLoader,
  invalidPromo,
  setInvalidPromo,
  source,
  roomTypeImages,
}) {
  const cartItemsFromRedux = useSelector((state) => state.cartItem);
  const dispatch = useDispatch();
  const [totalPromotionalDiscount, setTotalPromotionalDiscount] = useState(0);
  const handlePayAtHotel = () => {
    if (
      cartItemsFromRedux?.every(
        (item) =>
          allRoomTypeMap?.[item?.selectedRoom]?.combos?.[0]?.rates?.filter(
            (rate) => rate?.ratePlanId === item?.selectedRatePlan
          )[0]?.payAtProperty === "ON"
      )
    ) {
      if (source === "google") {
        setPayAtHotel(false);
      } else {
        setPayAtHotel(true);
      }
    } else {
      setPayAtHotel(false);
    }
  };

  const handleRoomStays = () => {
    const roomStaysArray = [];
    cartItemsFromRedux?.map((item) => {
      //handeling pay at hotel
      // if (
      //   allRoomTypeMap?.[item?.selectedRoom]?.combos?.[0]?.rates?.filter(
      //     (rate) => rate?.ratePlanId === item?.selectedRatePlan
      //   )[0]?.payAtProperty === "OFF"
      // ) {
      //   setPayAtHotel(false);
      // }
      //handeling roomStaysArray for payload.
      Array.from({ length: item?.propertyRooms }, (_, index) => index + 1).map(
        (elm) => {
          const roomStaysObject = {
            numAdults: item?.roomWiseAdults[`Room${elm}`],
            numChildren: item?.roomWiseChildren[`Room${elm}`],
            numChildren1: 0,
            roomTypeId: item?.selectedRoom,
            promotionThere: item?.promotionThere,
            bookingFee: allRoomTypeMap[item?.selectedRoom]?.bookingFee,
            bookingFeeName: allRoomTypeMap[item?.selectedRoom]?.bookingFeeName,
            bookingFeeArray:
              allRoomTypeMap[item?.selectedRoom]?.bookingFeeDetails,
            comboCheckIn: propertyAvailability?.checkInPretty,
            comboCheckOut: propertyAvailability?.checkOutPretty,
            ratePlanId: item?.selectedRatePlan,
            roomTypeName: allRoomTypeMap[item?.selectedRoom]?.roomTypeName,
            roomTaxesMap: allRoomTypeMap[item?.selectedRoom]?.roomTaxesMap,
          };

          roomStaysArray?.push(roomStaysObject);
        }
      );
    });
    setRoomStays(roomStaysArray);

    // setStep(5);
  };

  const updateCartItem = (roomId, ratePlanId, uid) => {
    setSelectedRoom(roomId);
    setSelectedRatePlan(ratePlanId);
    setEditableRateItem(ratePlanId);
    setIsEditCardItem(true);
    setCartItemUid(uid);
    setStep(3);
  };
  const handleAddnewRoom = () => {
    setSelectedRoom("");
    setSelectedRatePlan("");
    setIsEditCardItem(false);
    setStep(2);
  };
  const removeCartItem = (roomId, ratePlanId, uid) => {
    const myCartItems = [...cartItemsFromRedux];
    const index = cartItemsFromRedux?.findIndex((elm) => elm?.uid === uid);
    myCartItems.splice(index, 1);
    dispatch(saveCartItem(myCartItems));
    if (myCartItems?.length <= 0) {
      setStep(2);
    }
  };
  const grandTotalRoomChargesCalculation = () => {
    let promotionalDiscount = cartItemsFromRedux?.reduce(
      (cur, acc) => cur + acc?.promotionalDiscount,
      0
    );
    let amount = cartItemsFromRedux?.reduce(
      (cur, acc) => cur + acc?.selectedRoomCharges,
      0
    );
    let discountedAmount = cartItemsFromRedux?.reduce(
      (cur, acc) => cur + acc?.selectedRoomChargesAfterDiscount,
      0
    );
    let totalTax = cartItemsFromRedux?.reduce(
      (cur, acc) => cur + acc?.selectedRoomTaxes,
      0
    );
    let totalTaxDiscounted = cartItemsFromRedux?.reduce(
      (cur, acc) => cur + acc?.selectedRoomTaxesAfterDiscount,
      0
    );
    setgrandTotalRoomCharges(amount);
    setgrandTotalRoomChargesAfterDiscount(discountedAmount);
    setNonPromograndTotalRoomChargesAfterDiscount(discountedAmount);
    handleFeeCalculation(discountedAmount);
    setGrandTotalTaxes(totalTax);
    setGrandTotalTaxesAfterDiscount(totalTaxDiscounted);
    setNonPromoGrandTotalTaxesAfterDiscount(totalTaxDiscounted);
    setTotalPromotionalDiscount(promotionalDiscount);
  };

  const handleFeeCalculation = (discountedAmount) => {
    // debugger;
    // sample bookingFeeCalculationMap arry object is      {room_id:"1234",fee_type:"per_booking",fee_amount:123 }
    //calculating fee based on original base amount , not discounted.

    const roomTypeArrayAbs = [];
    const roomTypeArrayPers = [];
    let perBookingFeePercentage = 0;
    let bookingFee = 0;
    cartItemsFromRedux?.map((cartItem) => {
      const feeArray =
        allRoomTypeMap?.[cartItem?.selectedRoom]?.bookingFeeDetails;

      feeArray?.map((elm) => {
        if (elm?.fee_type === "per_booking") {
          if (elm?.value_type === "ABSOLUTE") {
            if (
              !roomTypeArrayAbs?.includes(
                `${cartItem?.selectedRoom}_${elm?.id}`
              )
            ) {
              roomTypeArrayAbs.push(`${cartItem?.selectedRoom}_${elm?.id}`);
              bookingFee += elm?.fee_value;
            }
          } else if (elm?.value_type === "PERCENT") {
            if (
              !roomTypeArrayPers?.includes(
                `${cartItem?.selectedRoom}_${elm?.id}`
              )
            ) {
              roomTypeArrayPers.push(`${cartItem?.selectedRoom}_${elm?.id}`);
              perBookingFeePercentage += elm?.fee_value;
            }
          }
        } else if (elm?.fee_type === "per_room") {
          if (elm?.value_type === "ABSOLUTE") {
            bookingFee += elm?.fee_value * cartItem?.propertyRooms;
          } else if (elm?.value_type === "PERCENT") {
            bookingFee +=
              elm?.fee_value *
              0.01 *
              cartItem?.selectedRoomChargesAfterDiscount;
          }
        } else if (elm?.fee_type === "per_room_night") {
          if (elm?.value_type === "ABSOLUTE") {
            bookingFee +=
              bookingDaysCount * elm?.fee_value * cartItem?.propertyRooms;
          } else if (elm?.value_type === "PERCENT") {
            bookingFee +=
              elm?.fee_value *
              0.01 *
              cartItem?.selectedRoomChargesAfterDiscount;
          }
        }
      });
    });
    bookingFee += perBookingFeePercentage * discountedAmount * 0.01;
    setBookingFee(bookingFee);
    console.log("bookingFee", bookingFee);
  };
  useEffect(() => {
    grandTotalRoomChargesCalculation();
    handleRoomStays();
    setPromocode("");
    setUserPromoDiscount(0);
    setPromoInfo({});
    handlePayAtHotel();
  }, [cartItemsFromRedux]);

  const handlePromoCodeRemoval = () => {
    setgrandTotalRoomChargesAfterDiscount(
      nonPromograndTotalRoomChargesAfterDiscount
    );
    setGrandTotalTaxesAfterDiscount(nonPromoGrandTotalTaxesAfterDiscount);
    setPromocode("");
    setUserPromoDiscount(0);
    setPromoInfo({});
  };

  //------------Accordian-----------------
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };
  //-----------expand description-----------
  const [expandDescription, setExpandDescription] = useState(false);
  const toggleExpand = () => {
    setExpandDescription((prev) => !prev);
  };
  useEffect(() => {
    setSelectedRoom("");
    setSelectedRatePlan("");
  }, []);
  return (
    <Box className="summary-wrapper">
      <Box className="summary-item">
        {cartItemsFromRedux?.map((items) => (
          <Box className="summary-item-card">
            <Card className="summary-card-header" sx={{ position: "relative" }}>
              <Box className="summary-media-container">
                {roomTypeImages?.[items?.selectedRoom]?.length > 1 ? (
                  <PropertyImageSlider
                    images={roomTypeImages?.[items?.selectedRoom]}
                  />
                ) : (
                  <CardMedia
                    className="cardmedia"
                    component="img"
                    image={
                      roomTypeImages?.[items?.selectedRoom]?.[0]?.imageUrl ||
                      defaultHotelImage
                    }
                    alt={`defaultHotelImage`}
                  />
                )}
              </Box>

              <Box>
                <Box sx={{ position: "absolute", top: 1, right: 1 }}>
                  <IconButton
                    onClick={() =>
                      updateCartItem(
                        items?.selectedRoom,
                        items?.selectedRatePlan,
                        items?.uid
                      )
                    }
                  >
                    <EditIcon
                      style={{
                        fontSize: "1.5rem",
                        color: "#1ab394",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      removeCartItem(
                        items?.selectedRoom,
                        items?.selectedRatePlan,
                        items?.uid
                      )
                    }
                  >
                    <ClearIcon
                      style={{
                        fontSize: "1.5rem",
                        color: "#1ab394",
                      }}
                    />
                  </IconButton>
                </Box>
                <CardContent
                  sx={{ flex: "1 0 auto", paddingBottom: "8px !important" }}
                >
                  <Box>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <HomeIcon sx={{ color: "#FFFFFF" }} />
                      <Typography variant="h6">
                        {allRoomTypeMap?.[items?.selectedRoom]?.roomTypeName} X{" "}
                        {items?.propertyRooms}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <PersonIcon sx={{ color: "#FFFFFF" }} />
                      <Box className="summary-adult-child">
                        <Typography variant="subtitle2">
                          Adults : {items?.totalAdult}{" "}
                        </Typography>
                        <Typography variant="subtitle2">
                          Children : {items?.totalChild}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <EqualizerIcon sx={{ color: "#FFFFFF" }} />
                      <Typography variant="subtitle2">
                        {
                          allRoomTypeMap?.[
                            items?.selectedRoom
                          ]?.combos?.[0]?.rates?.filter(
                            (elm) => elm?.ratePlanId === items?.selectedRatePlan
                          )?.[0]?.ratePlanName
                        }
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <PolicyIcon sx={{ color: "#FFFFFF" }} />
                      <>
                        {allRoomTypeMap?.[
                          items?.selectedRoom
                        ]?.combos?.[0]?.rates?.filter(
                          (elm) => elm?.ratePlanId === items?.selectedRatePlan
                        )?.[0]?.cancellationPolicyInfo?.policyRule ? (
                          allRoomTypeMap?.[
                            items?.selectedRoom
                          ]?.combos?.[0]?.rates?.filter(
                            (elm) => elm?.ratePlanId === items?.selectedRatePlan
                          )?.[0]?.cancellationPolicyInfo?.policyRule?.length <
                          50 ? (
                            <Typography variant="subtitle2">
                              {
                                allRoomTypeMap?.[
                                  items?.selectedRoom
                                ]?.combos?.[0]?.rates?.filter(
                                  (elm) =>
                                    elm?.ratePlanId === items?.selectedRatePlan
                                )?.[0]?.cancellationPolicyInfo?.policyRule
                              }
                            </Typography>
                          ) : (
                            <Typography variant="subtitle2">
                              {expandDescription
                                ? allRoomTypeMap?.[
                                    items?.selectedRoom
                                  ]?.combos?.[0]?.rates
                                    ?.filter(
                                      (elm) =>
                                        elm?.ratePlanId ===
                                        items?.selectedRatePlan
                                    )?.[0]
                                    ?.cancellationPolicyInfo?.policyRule?.substring(
                                      0
                                    )
                                : allRoomTypeMap?.[
                                    items?.selectedRoom
                                  ]?.combos?.[0]?.rates
                                    ?.filter(
                                      (elm) =>
                                        elm?.ratePlanId ===
                                        items?.selectedRatePlan
                                    )?.[0]
                                    ?.cancellationPolicyInfo?.policyRule?.substring(
                                      0,
                                      50
                                    )}
                              <Button
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                }}
                                onClick={toggleExpand}
                              >
                                {expandDescription
                                  ? " Read less"
                                  : " ...Read more"}
                              </Button>
                            </Typography>
                          )
                        ) : allRoomTypeMap?.[
                            items?.selectedRoom
                          ]?.combos?.[0]?.rates
                            ?.filter(
                              (elm) =>
                                elm?.ratePlanId === items?.selectedRatePlan
                            )?.[0]
                            ?.cancellationPolicyInfo?.policyDescription?.replace(
                              "\n",
                              ""
                            )?.length < 50 ? (
                          <Typography variant="subtitle2">
                            {allRoomTypeMap?.[
                              items?.selectedRoom
                            ]?.combos?.[0]?.rates
                              ?.filter(
                                (elm) =>
                                  elm?.ratePlanId === items?.selectedRatePlan
                              )?.[0]
                              ?.cancellationPolicyInfo?.policyDescription?.replace(
                                "\n",
                                ""
                              )}
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">
                            {expandDescription
                              ? allRoomTypeMap?.[
                                  items?.selectedRoom
                                ]?.combos?.[0]?.rates
                                  ?.filter(
                                    (elm) =>
                                      elm?.ratePlanId ===
                                      items?.selectedRatePlan
                                  )?.[0]
                                  ?.cancellationPolicyInfo?.policyDescription?.replace(
                                    "\n",
                                    ""
                                  )
                                  ?.substring(0)
                              : allRoomTypeMap?.[
                                  items?.selectedRoom
                                ]?.combos?.[0]?.rates
                                  ?.filter(
                                    (elm) =>
                                      elm?.ratePlanId ===
                                      items?.selectedRatePlan
                                  )?.[0]
                                  ?.cancellationPolicyInfo?.policyDescription?.replace(
                                    "\n",
                                    ""
                                  )
                                  ?.substring(0, 50)}
                            <Button
                              sx={{
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              onClick={toggleExpand}
                            >
                              {expandDescription
                                ? " Read less"
                                : " ...Read more"}
                            </Button>
                          </Typography>
                        )}
                      </>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <AttachMoneyIcon />
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {items?.promotionThere && (
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bolder",
                              textDecoration: "line-through",
                            }}
                          >
                            {currencySymbol}
                            {(
                              items?.selectedRoomCharges *
                              currencyConversionFactor
                            )?.toFixed(2)}
                          </Typography>
                        )}
                        <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                          {currencySymbol}
                          {(
                            items?.selectedRoomChargesAfterDiscount *
                            currencyConversionFactor
                          )?.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            </Card>

            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexFlow: "row",
              alignItems: "center",
              textTransform: "none",
              backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
              p: 1,
              borderRadius: 6,
              mt: 2,
            }}
          >
            <IconButton>
              <AddIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
            </IconButton>
            <Typography variant="h6">{currencySymbol} 25000 X 1</Typography>
            <IconButton>
              <RemoveIcon style={{ fontSize: "1.5rem", color: "#FFFFFF" }} />
            </IconButton>
          </Box> */}
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
            onClick={handleAddnewRoom}
          >
            Add another room
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Promo code
          </Typography>
          {promoInfo?.promoCode ? (
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Box>
                <Chip
                  label={promoInfo?.promoCode}
                  onDelete={handlePromoCodeRemoval}
                  deleteButton={
                    <IconButton>
                      <CloseIcon />
                    </IconButton>
                  }
                  color="primary"
                />
              </Box>
              <Typography variant="subtitle2">Code applied</Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ width: "90%" }}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  label="Enter promo code"
                  variant="outlined"
                  value={promocode}
                  onChange={(e) => {
                    setPromocode(e.target.value);
                    setInvalidPromo(false);
                  }}
                  helperText={
                    invalidPromo ? (
                      <span style={{ color: "red" }}>Invalid coupon code</span>
                    ) : null
                  }
                />
              </Box>
              <Button
                variant="custom-button"
                disabled={promoApplyLoader}
                onClick={handleRoomStaysPromoCodeValidation}
                sx={{ height: "40px", borderRadius: "27px" }}
              >
                Apply{" "}
                {promoApplyLoader && (
                  <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                )}
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Breakdown</Typography>
        </Box>

        <Box
          sx={{
            mt: 2,
            // p: 2,
            pt: 2,
            pb: 2,

            borderRadius: "8px",
            border: "0.5px solid #FFFFFF",
            display: "flex",
            flexDirection: "column",
            mb: 4,
          }}
        >
          {cartItemsFromRedux?.map((items) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pl: 2,
                pr: 2,
                mb: 1,
              }}
            >
              <Typography variant="subtitle2">
                {" "}
                {allRoomTypeMap?.[items?.selectedRoom]?.roomTypeName} :{" "}
                {
                  allRoomTypeMap?.[
                    items?.selectedRoom
                  ]?.combos?.[0]?.rates?.filter(
                    (elm) => elm?.ratePlanId === items?.selectedRatePlan
                  )?.[0]?.ratePlanName
                }{" "}
                <br></br>
                {bookingDaysCount} X {currencySymbol}
                {(
                  (items?.selectedRoomCharges / bookingDaysCount) *
                  currencyConversionFactor
                )?.toFixed(2)}
              </Typography>
              <Typography variant="subtitle2">
                {currencySymbol}
                {(
                  items?.selectedRoomCharges * currencyConversionFactor
                )?.toFixed(2)}

                {/* {currencySymbol}
                {
                  allRoomTypeMap?.[
                    items?.selectedRoom
                  ]?.combos?.[0]?.rates?.filter(
                    (elm) => elm?.ratePlanId === items?.selectedRatePlan
                  )?.[0]?.dayLevelPriceList?.[0]?.rates?.[1]
                } */}
              </Typography>
            </Box>
          ))}

          <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
              borderTop: "0.5px solid #FFFFFF",
              borderBottom: "0.5px solid #FFFFFF",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#FFFFFF", mr: "-3px" }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ background: "black" }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    Fee, taxes and discount
                  </Typography>
                </Grid>
                {/* <Grid item xs={6}>
                  <Typography variant="subtitle2" align="right">
                    {currencySymbol}
                    {(
                      (grandTotalTaxesAfterDiscount +
                        bookingFee +
                        totalPromotionalDiscount +
                        userPromoDiscount) *
                      currencyConversionFactor
                    )?.toFixed(2)}
                  </Typography>
                </Grid> */}
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "black" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  // pr: 1,
                }}
              >
                <Typography variant="subtitle2">Tax</Typography>
                <Typography variant="subtitle2">
                  {" "}
                  {currencySymbol}{" "}
                  {(
                    grandTotalTaxesAfterDiscount * currencyConversionFactor
                  )?.toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  // pr: 1,
                }}
              >
                <Typography variant="subtitle2">Fee</Typography>
                <Typography variant="subtitle2">
                  {" "}
                  {currencySymbol}{" "}
                  {(bookingFee * currencyConversionFactor)?.toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  // pr: 1,
                }}
              >
                <Typography variant="subtitle2">Promotional offer</Typography>
                <Typography variant="subtitle2">
                  {" "}
                  {currencySymbol}{" "}
                  {(
                    totalPromotionalDiscount * currencyConversionFactor
                  )?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                  // pr: 1,
                }}
              >
                <Typography variant="subtitle2">Discount</Typography>
                <Typography variant="subtitle2">
                  {" "}
                  {currencySymbol}{" "}
                  {(userPromoDiscount * currencyConversionFactor)?.toFixed(2)}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pl: 2,
              pr: 2,
              mt: 1,
            }}
          >
            <Typography variant="subtitle2">Total</Typography>
            <Typography variant="subtitle2">
              {currencySymbol}{" "}
              {(
                (grandTotalRoomChargesAfterDiscount +
                  bookingFee +
                  grandTotalTaxesAfterDiscount) *
                currencyConversionFactor
              )?.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Cancellation policies</Typography>
        </Box> */}
        {/* <Box sx={{ mb: 4 }}>
          <List sx={{ width: "100%", color: "#FFFFFF" }} aria-label="contacts">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon sx={{ color: "#FFFFFF", fontSize: "1rem" }} />
                </ListItemIcon>
                <ListItemText primary="This booking is non-refundable and the tariff cannot be cancelled with zero fee." />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <StarIcon sx={{ color: "#FFFFFF", fontSize: "1rem" }} />
                </ListItemIcon>
                <ListItemText primary="This booking is non-refundable and the tariff cannot be cancelled with zero fee." />
              </ListItemButton>
            </ListItem>
          </List>
        </Box> */}

        {/* <Box className="book-now-button">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              textTransform: "none",
              backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
              cursor: "pointer",
            }}
            onClick={() => {
              setStep(5);
            }}
          >
            <Box sx={{ pl: 3 }}>
              <Typography variant="h6">
                {currencySymbol} {grandTotalRoomChargesAfterDiscount}
              </Typography>
              <Typography variant="caption">excluding taxes & fees</Typography>
            </Box>
            <Box sx={{ pr: 3 }}>
              <KeyboardDoubleArrowRightIcon
                style={{ fontSize: "2.8rem", color: "#FFFFFF" }}
              />
            </Box>
          </Box>
        </Box> */}

        <Box>
          <Button
            variant="custom-button"
            onClick={() => {
              setStep(5);
            }}
            sx={{ width: "100%", borderRadius: "27px" }}
          >
            Continue
          </Button>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
