import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import pgfailed from "../asset/Failed.png";
import "../css/ErrorPages.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { handleAmplitude } from "../amplitude/AmplitudeUtils";

export default function PGFailed() {
  const { hotelId, refId } = useParams();
  const navigate = useNavigate();
  const handleToRedirectPage = () => {
    navigate(`/${hotelId}`);
  };
  useEffect(() => {
    if (refId && hotelId) {
      handleAmplitude({ hotelId: hotelId }, refId, hotelId);
    }
  }, [refId, hotelId]);
  return (
    <Box className="notfount-container">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: 2,
        }}
      >
        <img src={pgfailed} style={{ width: "100%" }} />
        <Typography variant="h5">
          Sorry, something unexpected has happened and we failed to confirm the
          booking.{" "}
          {refId === "not_available" && (
            <span>
              Requested room is not available. Please try an alternate room or
              dates
            </span>
          )}
          {refId === "payment_declined" && (
            <span>
              Payment method was declined. Please try an alternate payment
              method
            </span>
          )}
        </Typography>

        <Button
          variant="custom-button"
          sx={{ mt: 2, borderRadius: "27px" }}
          onClick={handleToRedirectPage}
        >
          Book again
        </Button>
      </Box>
    </Box>
  );
}
