import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Routers from "./routes/Routers";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { ThemeProvider, createTheme } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1BC5BD",
    },
    secondary: {
      main: "#3783e7",
    },
    error: {
      main: "#cc5965",
    },
  },
  typography: {
    h1: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    h2: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    h3: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    h4: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    h5: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    h6: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    subtitle1: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    subtitle2: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    body1: {
      fontWeight: "bold",
    },
    body2: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
    caption: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "#FFFFFF", // Set label color to white
          },
          "& .MuiInputBase-root": {
            color: "#FFFFFF", // Set text color to white
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FFFFFF", // Set border color to white
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#808080", // Set border color to white on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FFFFFF", // Set border color to white on focus
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#FFFFFF",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "custom-button" },
          style: {
            textTransform: "none",
            backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
            color: "white",
            fontWeight: "bold",
            height: "2.8rem",
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "secondary-button" },
          style: {
            textTransform: "none",
            backgroundColor: "gray",
            color: "white",
            opacity: 0.8,
            "&:hover": {
              textTransform: "none",
              backgroundColor: "gray",
              color: "white",
              opacity: 1,
            },
          },
        },
      ],
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: "custom-button" },
          style: {
            textTransform: "none",
            backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
            color: "white",
          },
        },
      ],
    },
  },
});
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Routers />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
