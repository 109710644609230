import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import WcIcon from "@mui/icons-material/Wc";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
export default function DateSelectionHeader({
  adults,
  children,
  dateRange,
  setStep,
  bookingDaysCount,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        p: 2,
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#FFFFFF" }} />
          <Typography variant="subtitle2">
            {moment(dateRange?.[0]?.startDate).format("Do MMMM YYYY")} -{" "}
            {moment(dateRange?.[0]?.endDate).format("Do MMMM YYYY")}
          </Typography>
        </Box>
        {/* <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <EscalatorWarningIcon sx={{ color: "#FFFFFF" }} />
          <Typography variant="subtitle2">
            Children selected : {children}
          </Typography>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <NightShelterIcon sx={{ color: "#FFFFFF" }} />
          <Typography variant="subtitle2">
            Nights selected : {bookingDaysCount}
          </Typography>
        </Box>
        {/* <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <WcIcon sx={{ color: "#FFFFFF" }} />
          <Typography variant="subtitle2">
            Adults selected : {adults}
          </Typography>
        </Box> */}
      </Box>

      <Box>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            borderWidth: "2px",
            borderRadius: "27px",
          }}
          onClick={() => setStep(1)}
        >
          Edit
        </Button>
      </Box>
    </Box>
  );
}
