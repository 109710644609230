import { Box, Typography } from "@mui/material";
import React from "react";
import notFoundImage from "../asset/NotFoundImage.png";
import "../css/ErrorPages.scss";

export default function NotFound() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <Box className="notfount-container">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: 2,
        }}
      >
        <img src={notFoundImage} style={{ width: "100%" }} />
        <Typography variant="h5">
          {" "}
          It seems you missed the hotel id! Check the url and try again
        </Typography>
      </Box>
    </Box>
  );
}
