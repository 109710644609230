import { AMPLITUDE_KEY, ENV } from "../utils/constants";
import amplitude from "amplitude-js";

export const handleAmplitude = (properties, event, hotelId) => {
  if (ENV === "PROD") {
    console.log("event triggered: ", event);
    amplitude.getInstance().init(AMPLITUDE_KEY, "", {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    });
    const deviceId = amplitude.getInstance().options.deviceId;
    console.log("deviceId", deviceId);
    amplitude.getInstance().setUserId("user_" + deviceId);

    amplitude
      .getInstance()
      .identify(new amplitude.Identify().set("hotel_id", hotelId));

    amplitude.getInstance().logEvent(event, properties);
  }
};
