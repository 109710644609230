import { Box, Typography, FormControl, Select, MenuItem } from "@mui/material";
import "../css/Header.scss";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import Currencies from "../utils/Currencies.json";
import { useDispatch, useSelector } from "react-redux";
import { saveCurrency } from "../redux/slice/slice";

export default function Header() {
  const propertyInfo = useSelector((state) => state.PropertyInfo);
  const dispatch = useDispatch();
  // const [currency, setCurrency] = useState("INR");
  const currency = useSelector((state) => state.Currency);
  const handleCurrenySelection = (e) => {
    // setCurrency(e.target.value);
    dispatch(saveCurrency(e.target.value));
  };
  const [expandPropertyName, setExpandPropertyName] = useState(false);
  const toggleExpand = () => {
    setExpandPropertyName((prev) => !prev);
  };
  return (
    <Box>
      <Box className="header">
        <Box className="desktop-info">
          <Typography variant="h6">{propertyInfo?.propertyName}</Typography>
        </Box>
        <Box className="mobile-info">
          {propertyInfo?.propertyName?.length < 30 ? (
            <Typography variant="h6">{propertyInfo?.propertyName}</Typography>
          ) : (
            <Typography variant="h6">
              {expandPropertyName
                ? propertyInfo?.propertyName?.substring(0)
                : propertyInfo?.propertyName?.substring(0, 30)}{" "}
              {expandPropertyName || <span onClick={toggleExpand}>...</span>}
            </Typography>
          )}
        </Box>
        <Box>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                width: 100,
                height: 30,
                borderRadius: "15px",
                // mr: 2,
                border: "1px solid #FFFFFF",
                color: "#fff",
              }}
              value={currency}
              onChange={handleCurrenySelection}
            >
              {Currencies?.map((item) => (
                <MenuItem value={item?.code}>{item?.code}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
}
